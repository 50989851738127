import React from 'react'
import styled from 'styled-components';

interface NavButtonProps{
    width: string | undefined;
    height: string | undefined;
    colorFillCenter: string | undefined;
    colorStrokeCenter: string |undefined;
    colorFillMain: string | undefined;
    colorStrokeMain: string | undefined;
}

export default function NavBtn(props: NavButtonProps) {
    return (
        
<Svg version="1.1" id="btn_x5F_activ" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
	 y="0px" width={props.width} height={props.height} viewBox="0 0 200 200" enableBackground ="new 0 0 200 200" xmlSpace="preserve">
<circle id="main" fill={props.colorFillMain} stroke={props.colorStrokeMain} strokeWidth="12" strokeMiterlimit="10" cx="100" cy="100" r="81.269"/>
<ellipse id="center" fill={props.colorFillCenter} stroke={props.colorStrokeCenter} strokeWidth ="12" strokeMiterlimit="10" cx="100" cy="100" rx="24.189" ry="22.566"/>
</Svg>
    )
}

const Svg = styled.svg `
    
    :hover{
        filter:brightness(0.5);
        transform: scale(1.2);
        opacity: 0.8;
        cursor: pointer;
        
    }
`
