/**
 * Carrousel
 */
import carousel_01 from "../images/Carousel/home_01.jpg";
import carousel_02 from "../images/Carousel/maintenance_02.jpg";
import carousel_03 from "../images/Carousel/graphisme_01.jpg";
import carousel_04 from "../images/Carousel/maintenance_01.jpg";
/**WebBall first img */
import webBowlFirstImg from "../images/loadingImages/sphere_0000.png";
/*Prestations*/
import cahierDesChargesImg from "../images/prestas/presta_design.png";
import Ux from "../images/prestas/presta_ux.png";
import media from "../images/prestas/presta_media.png";
import devFront from "../images/prestas/presta_frontend.png";
import devBack from "../images/prestas/presta_backend.png";
import maintenance from "../images/prestas/presta_maintenance.png";
import anims from "../images/prestas/presta_anims.png";
import plus from "../images/prestas/presta_help.png";

import diplomas from "../images/diplomas_3d.png";
import experience from "../images/experience_3d.png";
import info from "../images/infos_3d.png";
import skills from "../images/skills_3d.png";
import About from "../pages/About";
import Maintenance from "../pages/Services";
import DevWeb from "../pages/DevWeb";
import Home from "../pages/Home";
import Portfolio from "../pages/Portfolio";
import "../styles/App.css";
import compass00 from "../media/compass/boussole_0000.png";
import compass01 from "../media/compass/boussole_0001.png";
import compass02 from "../media/compass/boussole_0002.png";
import compass03 from "../media/compass/boussole_0003.png";
import compass04 from "../media/compass/boussole_0004.png";
import compass05 from "../media/compass/boussole_0005.png";
import compass06 from "../media/compass/boussole_0006.png";
import compass07 from "../media/compass/boussole_0007.png";
import compass08 from "../media/compass/boussole_0008.png";
import compass09 from "../media/compass/boussole_0009.png";
import compass10 from "../media/compass/boussole_0010.png";
import compass11 from "../media/compass/boussole_0011.png";
import compass12 from "../media/compass/boussole_0012.png";
import compass13 from "../media/compass/boussole_0013.png";
import compass14 from "../media/compass/boussole_0014.png";
import compass15 from "../media/compass/boussole_0015.png";
import compass16 from "../media/compass/boussole_0016.png";
import compass17 from "../media/compass/boussole_0017.png";
import compass18 from "../media/compass/boussole_0018.png";
import compass19 from "../media/compass/boussole_0019.png";
import compass20 from "../media/compass/boussole_0020.png";
import compass21 from "../media/compass/boussole_0021.png";
import compass22 from "../media/compass/boussole_0022.png";
import compass23 from "../media/compass/boussole_0023.png";
import compass24 from "../media/compass/boussole_0024.png";
import compass25 from "../media/compass/boussole_0025.png";
import compass26 from "../media/compass/boussole_0026.png";
import compass27 from "../media/compass/boussole_0027.png";
import compass28 from "../media/compass/boussole_0028.png";
import compass29 from "../media/compass/boussole_0029.png";
import compass30 from "../media/compass/boussole_0030.png";
import compass31 from "../media/compass/boussole_0031.png";
import compass32 from "../media/compass/boussole_0032.png";
import compass33 from "../media/compass/boussole_0033.png";
import compass34 from "../media/compass/boussole_0034.png";
import compass35 from "../media/compass/boussole_0035.png";
import compass36 from "../media/compass/boussole_0036.png";
import compass37 from "../media/compass/boussole_0037.png";
import compass38 from "../media/compass/boussole_0038.png";
import compass39 from "../media/compass/boussole_0039.png";
import compass40 from "../media/compass/boussole_0040.png";

/*bottle anim*/
import bottle00 from "../media/bottle/bottle_0000.png";
import bottle01 from "../media/bottle/bottle_0001.png";
import bottle02 from "../media/bottle/bottle_0002.png";
import bottle03 from "../media/bottle/bottle_0003.png";
import bottle04 from "../media/bottle/bottle_0004.png";
import bottle05 from "../media/bottle/bottle_0005.png";
import bottle06 from "../media/bottle/bottle_0006.png";
import bottle07 from "../media/bottle/bottle_0007.png";
import bottle08 from "../media/bottle/bottle_0008.png";
import bottle09 from "../media/bottle/bottle_0009.png";
import bottle010 from "../media/bottle/bottle_0010.png";
import bottle011 from "../media/bottle/bottle_0011.png";
import bottle012 from "../media/bottle/bottle_0012.png";
import bottle013 from "../media/bottle/bottle_0013.png";
import bottle014 from "../media/bottle/bottle_0014.png";
import bottle015 from "../media/bottle/bottle_0015.png";
import bottle016 from "../media/bottle/bottle_0016.png";
import bottle017 from "../media/bottle/bottle_0017.png";
import bottle018 from "../media/bottle/bottle_0018.png";
import bottle019 from "../media/bottle/bottle_0019.png";
import bottle020 from "../media/bottle/bottle_0020.png";

/**/
import logoVivendi from "../images/logo_business/logo_Vivendi.png";
import logoBulkypix from "../images/logo_business/logo_Bulkypix.png";
import logoCiteDesSciences from "../images/logo_business/logo_citeDesSciences.png";
import logoCoktel from "../images/logo_business/logo_Coktel.png";
import logoUlysse from "../images/logo_business/logo_EcurieUlysse.png";
import logoGimagin from "../images/logo_business/logo_Gimagin.png";
import logoNomattitude from "../images/logo_business/logo_Nomattitude.png";
import logoPulsanim from "../images/logo_business/logo_Pulsanim.png";
import logoSlt from "../images/logo_business/logo_Slt.png";
import logoAma from "../images/logo_business/logo_Ama.png";
import logoVeolia from "../images/logo_business/logo_Veolia.png";
import logoLaura from "../images/logo_business/logo_chateau.png";
import logoIngrid from "../images/logo_business/logo_Equilys.png";
import logoIf from "../images/logo_business/logo_iF.png";
import mediaProg from "../images/aLaUne.png";
import mediaUx from "../images/mediaUx.png";
import vivi from "../media/vivi.mp4";
import prisonbreak from "../media/prisonbreak.mp4";
import spiderwick from "../media/spiderwick.mp4";
import Prestations from "../pages/Prestations";

import iconUx from "../images/prestas/presta_ux.png";
import iconDesign from "../images/prestas/presta_design.png";
import iconProg from "../images/progGene.png";
import Services from "../pages/Services";

import laTable from "../images/portfolio/Gallery_site_LaTable.png";
import Nomattitude from "../images/portfolio/Gallery_site_Nomattitude.png";
import UlysseBooking from "../images/portfolio/Gallery_site_reservations_Ulysse.png";
import UlysseSite from "../images/portfolio/Gallery_site_Ulysse.png";
import EquilysSite from "../images/portfolio/Gallery_site_Equilys.png";
import Castlebarn from "../images/portfolio/Gallery_site_Castlebarn.png";
import Viterbe from "../images/portfolio/Gallery_site_ViterbePropale.png";
const webBowlImports = require.context("../../public/Anims/Web/", true, /\.png$/);
const webBowlPath = webBowlImports.keys().map(myImport => myImport.replace("./", "Anims/Web/"));


interface ProjectData {
    id: number;
    title: string;
    description: string;
    icon: string;
}

export const sites = [
    {id: 0, img: EquilysSite, alt: "Site EquiLys", siteDetails: "Site internet dynamique avec vidéos, animations interactives de l'UI, formulaire de contact, formulaires téléchargeables et signature numérique. Module d'administration pour mettre à jour les actualités avec upload d'images. Réalisé pour EquiLys Ingrid, monitrice d'équitation", link: "https://equilys-ingrid.fr", job : "Création et développement du site", details: "Librairie React et serveur Php. Développé en typescript pour le front end"},
    {id: 1, img: Castlebarn, alt: "Site de l'Écurie du Château", siteDetails: "Page vitrine avec quelques composants animés, menu personnalisé et widget facebook. Réalisé pour L'Écurie du Château, Villematier ", link: "https://ecurieduchateau.fr", job : "Conception, contenu - médias et développement du site ", details: "Librairie React. Développé en typescript html / css / styled components"},
    {id: 2, img: UlysseSite, alt: "Site des Écuries d'Ulysse", siteDetails: "Site internet dynamique avec vidéos, animations interactives de l'UI, formulaire de contact et carte Google Map. Réalisé pour Les Écuries d'Ulysse", link: "https://lesecuriesdulysse.fr", job : "Refonte et développement du site | supports de communication", details: "Librairie React et serveur Php. Développé en typescript pour la partie front"},
    {id: 3, img: UlysseBooking, alt: "Plateforme de réservation des Écuries d'Ulysse", siteDetails: "Plateforme de réservation en ligne. Front office permettant la réservation de plusieurs activités avec paiement en ligne. Possibilité de visualiser puis modifier les réservations. Back office pour la gestion des crénaux de réservation disponibles par type d'activité et liste des réservations à venir", link: "https://lesecuriesdulysse.club", job : "UX, Maquettage et développement", details: "Librairie React et serveur Node. Développé en typescript et javascript"},
    {id: 4, img: Nomattitude, alt: "CMS Nomattitude", siteDetails: "CMS allégé NOMATTITUDE. Front office proposant des sondages et des communications à la clientèle du produit phare de la société. Backoffice permettant de créer / modifier / supprimer des articles par type de communication. ", link: "https://www.nomattitude.com/", job : "Développement d'un CMS allégé. Accessible uniquement avec login sur Hermes", details: "Développement C# et javascript"},
    {id: 5, img: Viterbe, alt: "institutionnel", siteDetails: "Site institutionnel pour la mairie de Viterbe", link: "Proposition de design", job : "Design intégré à proposition", details: "Développement React TS"},
    {id: 6, img: laTable, alt: "la Table", siteDetails: "Site vitrine de restauration", link: "Exemple de design", job : "Maquettage et développement frontend", details: "Développement React JS"}
]
export const videos = [
    { id: 1, url: vivi, info: "Trailer Virtual villager" },
    { id: 2, url: prisonbreak, info: "Trailer prison break" },
    { id: 3, url: spiderwick, info: "Trailer spiderwick" },
]

export const bottles = [
    bottle00, bottle01, bottle02, bottle03, bottle04, bottle05,
    bottle06, bottle07, bottle08, bottle09, bottle010, bottle011,
    bottle012, bottle013, bottle014, bottle015, bottle016, bottle017,
    bottle018, bottle019, bottle020
]

export const DIPLOMAS = [
    /*DIPLOMES*/
    { id: 0, sectionid: 0, title: "Concepteur développeur WEB niveau II", date: "2019", description: "Spécialisation en développement WEB et smartphone : UX Map - UX Design - Bibliothèques Javascript REACT / REACT NATIVE, ANGULAR - Php SYMFONY, JAVA POUR SMARTPHONE", level: "Diplômée" },
    { id: 1, sectionid: 0, title: "BTS SIO -  option SLAM ", date: "2017", description: "Services Informatiques aux Organisations option Solutions Logicielles Et Applications Métiers : Acquisition des bases de la programmation bas niveaux et langages de bases de données - Programmation WEB et SMARTPHONE (Java pour Androïd)", level: "Diplômée" },
    { id: 2, sectionid: 0, title: "Concours d'entrée à l'école d'orthophonie de Paris", date: "2014", description: "Approfondissement des difficultés de la langue française", level: "Admissible à l'oral" },
    { id: 3, sectionid: 0, title: "Les Gobelins Paris Principes de l’animation et du StoryBoard", date: "2008", description: "Travail sur l'enchaînement des plans et le cadrage d'un film d'animation", level: "Formation professionnelle" },
    { id: 4, sectionid: 0, title: "Les Gobelins Paris Maya (logiciel de graphisme 3D) ", date: "2005", description: "", level: "Formation professionnelle" },
    { id: 5, sectionid: 0, title: "Ateliers des Beaux Arts Paris", date: "2000", description: "Ateliers : Modèle vivant et nature morte", level: "Formation personnelle" },
    { id: 6, sectionid: 0, title: "Formations graphiques techniques : EMC, IESA Paris", date: "1997", description: "Formations aux outils graphiques : Photoshop, 3DSMAX, Illustrator, bases de HTML et de Javascript", level: "Formation non diplômante" },
    { id: 7, sectionid: 0, title: "CPEG CNED Poitiers", date: "1996", description: "Certificat préparatoire aux études de gestion", level: "Admise" },
    { id: 8, sectionid: 0, title: "BTS CI - Commerce International", date: "1995", description: "Première langue : anglais - deuxième langue : allemand - droit, marketing, logistique à l'international", level: "Diplômée" },
    { id: 9, sectionid: 0, title: "BAC A1 - Lettres et mathématiques", date: "1992", description: "Première langue : anglais - deuxième langue : allemand - mathématiques, philosophie", level: "Diplômée" },


]
export const EXPERIENCE = [
    /*EXPERIENCE*/
    { id: 11, sectionid: 1, title: "Développement et maintenance de sites internet", date: "Depuis 2020", description: "Développement web full stack en REACT (typescript ou js) avec serveur node (typescript) et mises en production. Production de médias. Maintenance", level: "" },
    { id: 12, sectionid: 1, title: "Nomattitude, Castelnau d’Estrétefonds (31620)", date: "2018-2019", description: "Géolocalisation Développement en C# ASP.net pour le back-end - javascript et composants telerik pour le front-end ", level: " Composants Telerik, webservices, intégration cartes, pointeurs et circuit GoogleMaps, développement smartphone en delphi" },
    { id: 13, sectionid: 1, title: "PPE dans le cadre du BTS SIO", date: "2017", description: "Développement PHP sous Netbeans - Virtualisation Vmware Esxi. Service Windows en C#. Dans le cadre des TP, développement d’une application pour smartphone sous Android Studio, développement d’un jeu 2D client/serveur en Java", level: "" },
    { id: 14, sectionid: 1, title: "Mairie de Savigny-le-Temple (77176)", date: "2016", description: "Stage 10 semaines: développement d’une application de gestion des adhérents des centres sociaux de la ville sous Access en VBA", level: "" },
    {
        id: 15, sectionid: 1, title: "Missions freelances en graphisme 3D", date: "2005-2015", description: "Modélisation, mapping, skinning, effets spéciaux et rendu sous 3DSMax", level: "Domaines d'intervention : jeux vidéos,  communication institutionnelle, jingles pub, génériques",
        details: <div className="xp-details">
            <span className="xp-details-title" >+ Bulkypix</span>, Vélizy (78)
            <div className="xp-details">
                3D pour smartphones et tablettes
            </div>
            <span className="xp-details-title" >+</span>En sous-traitance pour <span className="xp-details-title" >Veolia</span>
            <div className="xp-details">
                Haute définition, effets spéciaux
            </div>
            <span className="xp-details-title" >+Cité des sciences Fablabs, Cité des jeux vidéo</span> - Paris
            <div className="xp-details">
                Cinématiques 3D
            </div>
            <span className="xp-details-title" >+Talented People pour AMA Studios</span> - Belgique
            <div className="xp-details">
                FIGHTERS UNCAGED d’<span className="xp-details-title" >Ubisoft</span> sur Xbox 360
            </div>
            <span className="xp-details-title" >+ Cité des sciences La Villette </span> « Le Rêve de Galilée »
            <div className="xp-details">
                Sur l’ écran hémisphérique du <span className="xp-details-title" >Planétarium</span>
            </div>
            <span className="xp-details-title" >+Interactif Factory </span>- St-Ouen (93)
            <div className="xp-details">
                Habillages tv, clips 3D, jingles pub
            </div>
            <span className="xp-details-title" >+Ginger Studio</span> - Paris
            <div className="xp-details">
                Publicité Web pour la sortie de la PSP
            </div>
        </div>
    },
    { id: 16, sectionid: 1, title: "Vivendi Games Mobile, Vélizy (78)", date: "2006-2010", description: "Responsable 3D", level: "" },
    { id: 17, sectionid: 1, title: "Coktel, Vélizy (78)", date: "1999-2005", description: "Graphiste 3D", level: "" },
    { id: 18, sectionid: 1, title: "Green Mouse, Montreuil (93)", date: "1998-1999", description: "Maquettiste PAO", level: "" },
    { id: 19, sectionid: 1, title: "Hewlett Packard, Évry (91)", date: "1994-1998", description: "Gestionnaire de contrats de maintenance", level: "" },
]
export const INFORMATION = [
    /*INFOS*/
    { id: 11, sectionid: 2, title: "", date: "Contact", description: "Mel : postmaster@latoiledenbouyssou.fr", level: "Adresse : 12, chemin d'En Bouyssou 81220 Viterbe", details: <span className="xp-details">Mobile : 06 73 47 58 26</span> },
    { id: 12, sectionid: 2, title: "", date: "État civil", description: "Née le 20 janvier 1974 à Paris", level: "PACSÉE - 2 enfants", details: <span className="xp-details">Nationalité française</span> },
    { id: 13, sectionid: 2, title: "", date: "Infos", description: "Permis A et B", level: "Loisirs : Équitation, danse", details: <span className="xp-details">Langue : anglais (B2)</span> },
    { id: 14, sectionid: 2, title: "", date: "Atouts", description: "Rigueur, Curiosité intellectuelle", level: "Autonomie, Sociabilité" }

]
export const SKILLS = [
    /*COMPETENCES*/
    { id: 11, sectionid: 3, title: "Programmation backend ", date: "", description: "Typescript -  C#", level: "JAVA - PHP - Symfony, doctrine - VBA - Python" },
    { id: 12, sectionid: 3, title: "Programmation frontend", date: "", description: "React - Html, Css et javascript", level: "Angular" },
    { id: 13, sectionid: 3, title: "Langages de bases de données", date: "", description: "PostgreeSql - mySql", level: "" },
    { id: 14, sectionid: 3, title: "Modélisation de données", date: "", description: "Merise / UML", level: "" },
    { id: 15, sectionid: 3, title: "Graphisme 3D", date: "", description: "3DsMax, Photoshop, Illustrator", level: "" },
    { id: 16, sectionid: 3, title: "PAO", date: "", description: "Affiches, logos, brochures, flyers...", level: "" },
    { id: 17, sectionid: 3, title: "UX", date: "", description: "Persona, Ux map, maquettes", level: "" },
    { id: 18, sectionid: 3, title: "Système de contrôle de version", date: "", description: "git, alienbrain", level: "Commandes de base" }
]

export const SECTIONS = [
    { id: 0, title: "Diplômes ", image: diplomas },
    { id: 1, title: "Expérience", image: experience },
    { id: 2, title: "Informations", image: info },
    { id: 3, title: "Compétences", image: skills }
];
export const COMPASS = [
    { id: 0, img: compass00 },
    { id: 1, img: compass01 },
    { id: 2, img: compass02 },
    { id: 3, img: compass03 },
    { id: 4, img: compass04 },
    { id: 5, img: compass05 },
    { id: 6, img: compass06 },
    { id: 7, img: compass07 },
    { id: 8, img: compass08 },
    { id: 9, img: compass09 },
    { id: 10, img: compass10 },
    { id: 11, img: compass11 },
    { id: 12, img: compass12 },
    { id: 13, img: compass13 },
    { id: 14, img: compass14 },
    { id: 15, img: compass15 },
    { id: 16, img: compass16 },
    { id: 17, img: compass17 },
    { id: 18, img: compass18 },
    { id: 19, img: compass19 },
    { id: 20, img: compass20 },
    { id: 21, img: compass21 },
    { id: 22, img: compass22 },
    { id: 23, img: compass23 },
    { id: 24, img: compass24 },
    { id: 25, img: compass25 },
    { id: 26, img: compass26 },
    { id: 27, img: compass27 },
    { id: 28, img: compass28 },
    { id: 29, img: compass29 },
    { id: 30, img: compass30 },
    { id: 31, img: compass31 },
    { id: 32, img: compass32 },
    { id: 33, img: compass33 },
    { id: 34, img: compass34 },
    { id: 35, img: compass35 },
    { id: 36, img: compass36 },
    { id: 37, img: compass37 },
    { id: 38, img: compass38 },
    { id: 39, img: compass39 },
    { id: 40, img: compass40 }

]
export const domains = [
    { id: 0, title: <span className="subTitle2">Conception</span> },
    { id: 1, title: <span className="subTitle2">Développement web</span> },
    { id: 2, title: <span className="subTitle2">Médias 2D / 3D</span> },
    { id: 3, title: <span className="subTitle2">Maintenance de site</span> }
]

export const gallery = [
    { id: 0, img: carousel_01 },
    { id: 1, img: carousel_02 },
    { id: 2, img: carousel_03 },
    { id: 3, img: carousel_04 }
];

const _project =
    [{
        id: 0,
        title: "Programmation",
        info: "Plateforme de réservation avec module de paiement",
        progDetails: "Développée avec React en javascript (le front-end) avec un serveur Node en typescript (le back-end). La plateforme est accessible en production via l'onglet 'Réservations' du site lesecuriesdulysse.fr ",
        toolsDetails: "Pour ce projet, j'ai choisi les API Stripe comme module de paiement et Mailgun pour l'envoi de mails certifiés",
        appDetails01: "Plateforme de réservation de prestations équestres. Plusieurs formules sont proposées avec chacune ses spécificités. Une fois la réservation passée, un mail est envoyé au client et à l'écurie. La réservation s'ajoute à la liste de celles en cours et peut être modifiée jusqu'à 3 heures avant l'heure du rendez-vous",
        appDetails02: "Un module d'administration (back office) a été créé pour gérer les créneaux disponibles à la réservation, désactiver certaines plages de dates et lister les réservations à venir.",
        bckg: mediaProg,
        access1: "Accéder à la plateforme beta",
        access2: "Accéder à la plateforme production",
        url1: "https://ulyssebooking.herokuapp.com/",
        url2: "https://lesecuriesdulysse.club/"
    },
    {
        id: 1,
        title: "UX design",
        info: "Plateforme de création et d'édition d'articles à paraître sur une plateforme de géolocalisation",
        progDetails: "Développée en C# MVC",
        toolsDetails: "Ce projet propose un UX pour faciliter l'interaction entre la plateforme de géolocalisation déjà en place et l'utilisateur",
        appDetails01: "Proposition faite à l'utilisateur d'essayer un produit s'il n'y est pas déjà abonné. Parution des communications (tutoriels, sondages ou communications institutionnelles) au sein de la plateforme de géolocalisation",
        appDetails02: "Un module d'administration (back office) a été créé pour créer les articles, les éditer ou les supprimer. Ce back office permet aussi de programmer un article à paraître avec des niveaux de priorité",
        bckg: mediaUx,
        access1: "Accéder à l'UX",
        access2: "Visualiser des impressions d'écran de la version beta",
        url1: "https://ulyssebooking.herokuapp.com/",
        url2: "https://lesecuriesdulysse.club/"
    }];



export const headline =
{
    id: 0,
    title: "Plateforme de réservation",
    bckg: mediaProg,
    url: "https://ulyssebooking.herokuapp.com/",
    infos:
        [
            {
                id: 0,
                title: "Flexible",
                info1: "Avec ou sans module de paiement",
                info2: "Plusieurs formules de réservation",
            },
            {
                id: 1,
                title: "Les outils",
                info1: "Techno moderne, souple et efficace : React en javascript pour le front-end et serveur Node en typescript pour le back-end"
            },
            {
                id: 2,
                title: "Les services externes",
                info1: "APIs reconnues : Stripe (module de paiement) et Mailgun (envoi de mails certifiés)"
            },
            {
                id: 3,
                title: "Module d'administration (back office)",
                info1: "Pour gérer les créneaux disponibles, désactiver certaines plages de dates et lister les réservations à venir"
            },
            {
                id: 4,
                title: "Créons votre plateforme personnalisée!",
                info1: "Testez le portail d'essai",
                info2: "Pour le back office merci d'utiliser le formulaire de contact, un identifiant temporaire vous sera communiqué"
            }
        ]
}


export const projectData: ProjectData[] | [] = [
    {
        id: 0,
        title: "Conception UX/UI Design",
        description: "La phase de conception met en place le projet. Elle s’intéresse avant tout à l’utilisateur car c’est à lui qu’on s’adresse : ainsi, selon le public ciblé, l'interface et le parcours de l'utilisateur seront totalement différents. " +
            "C'est l'UX map qui permet de tracer le parcours de notre internaute, ses motivations et ses freins. Puis on met en place la charte graphique. Le cahier des charges enterrine ensuite les choix fonctionnels et graphiques. Vient alors le prototypage qui pose les rouages de l’ Interface Utilisateur",
        icon: iconUx
    },
    {
        id: 1,
        title: "Réalisation Maquettage",
        description: "La phase de réalisation permet d’entrer dans le vif du sujet : " +
            "on produit les sources graphiques, photographies, pictogrammes, et autres illustrations. " +
            "Une première maquette fonctionnelle est alors proposée. En retour des tests utilisateurs, on peaufine la recette, puis on s’intéresse au développement des fonctionnalités",
        icon: iconDesign
    },
    {
        id: 2,
        title: "Production Développement",
        description: "C’est la phase qui varie le plus en fonction du type de site souhaité : site statique ou interactif, e-commerce ou autre application web.  Elle peut être assez courte dans le cas d’un site web statique mais peut également durer plusieurs mois pour les applications plus complexes",
        icon: iconProg
    }
]

export const maintenanceDetails = [
    {
        id: 0,
        title: "Maintenance préventive",
        description: "La maintenance préventive s'intéresse aux mises à jour. " +
            "C'est elle qui permet à votre site de durer dans le temps. " +
            "Les mises à jour sont essentielles pour la sécurité d'un site et doivent se faire régulièrement, surtout s'il s'agit d'une plateforme e-commerce. " +
            "La maintenance préventive inclut généralement les modifications peu importantes telles que les actualisations de contenu du type flux d'actualités",
        icon: ""
    },
    {
        id: 1,
        title: "Maintenance corrective",
        description: " Votre site a fait l'objet d'un piratage? C'est la maintenance corrective qui va s'occuper de réparer les dégâts et de faire en sorte que cela ne se reproduise plus. " +
            "Souscrite dès la création du site, elle permet de lutter efficacement contre les attaques." +
            "Dans cette catégorie, on peut inclure le https, les formulaires de connexion sécurisée, les doubles authentifications, les captcha...",
        icon: ""
    },
    {
        id: 2,
        title: "Maintenance évolutive",
        description: "Votre site est le reflet de votre société. " +
            " Vous souhaitez proposer de nouvelles fonctionnalités à vos clients? Créer de nouvelles rubriques? Faire évoluer votre site vers une technologie plus réactive? " +
            "La maintenance évolutive est faite pour ça!",
        icon: ""
    }
]
export const servicesData = [
    {
        id: 0,
        title: "Développement",
        static: "Création ou refonte de site internet",
        description: [
            {
                id: 0,
                category: "desc-row",
                title: "Site statique",
                infos: "Ux, design, frontend (javascript react, css)",
                price: [{ id: 0, title: "", category: "price-row", label: "à partir de 200€ / composant" }]
            },
            {
                id: 1,
                title: "Site dynamique",
                category: "desc-row",
                infos: "Ux, design, front, back",
                price: [{ id: 0, title: "", category: "price-row", label: "à partir de 300€ / composant" }]
            },
            {
                id: 2,
                title: "Application web",
                category: "desc-row",
                infos: "Ux, design, front, back",
                price: [{ id: 0, title: "", category: "price-row", label: "Sur devis" }]
            },
            {
                id: 3,
                title: "Forfait mise en ligne",
                category: "desc-row",
                infos: "Les frais d'hébergement et d'achat de nom de domaine sont à ajouter en fonction de vos besoins",
                price: [{ id: 0, title: "", category: "price-row", label: "à partir de 300€" }]
            },

        ],
        icon: ""
    },

    {
        id: 1,
        title: "Communication",
        static: "Harmonisation de supports publicitaires avec votre site",
        description: [
            {
                id: 0,
                title: "PAO",
                category: "desc-row",
                infos: "Logos, brochures, flyers",
                price: [{ id: 0, title: "", category: "price-row", label: "50€ / heure" }]
            },
            {
                id: 1,
                title: "Réseaux sociaux",
                category: "desc-row",
                infos: "Bandeaux, logos et affiches de vos événements",
                price: [{ id: 0, title: "", category: "price-row", label: "50€ / heure" }]
            }
        ],
        icon: ""
    },
    {
        id: 2,
        title: "Renfort d'équipe",
        static: "Entreprise - Agence de com -  ESN ",
        description: [
            {
                id: 0,
                title: "Développement front",
                category: "desc-row",
                infos: "HTML / Javascript / Typescript / React / Css",
                price: [{ id: 0, title: "", category: "price-row", label: "350€ HT / jour, au forfait ou salariat" }]
            },
            {
                id: 1,
                title: "Développement back",
                category: "desc-row",
                infos: "Typescript / Javascript ( Liste des langages pratiqués dans la rubrique À Propos / Compétences )",
                price: [{ id: 0, title: "", category: "price-row", label: "350€ HT / jour, au forfait ou salariat" }]
            },
            {
                id: 2,
                title: "Design, 3D, R&D",
                category: "desc-row",
                infos: "3DSMax, Photoshop, Animations css",
                price: [{ id: 0, title: "", category: "price-row", label: "300€ HT / jour, au forfait ou salariat" }]
            }
        ],
        icon: ""
    }
]
export const savData = [
    {
        id: 3,
        title: "Maintenance préventive",
        static: "La maintenance préventive s'intéresse aux mises à jour. " +
        "C'est elle qui permet à votre site de durer dans le temps. " +
        "Les mises à jour sont essentielles et doivent se faire régulièrement, par simple souci de sécurité. Ces packs " +
        " sont les entrées de gamme en matière de service après vente." +
        " La maintenance préventive inclut les modifications peu importantes telles que les actualisations de contenu textuel, dans la limite d'une modification par semestre.",
        description: [
            {
                id: 0,
                category: "desc-row",
                title: "",
                infos: "",
                price: [
                    { id: 0, title: "Site statique", category: "price-row", label: "À partir de 25€ HT / mois" },
                    { id: 1, title: "Site dynamique", category: "price-row", label: "À partir de 50€ HT / mois" },
                    { id: 2, title: "Application web", category: "price-row", label: "À partir de 100€ HT / mois" },
                ]
            }
           

        ],
        icon: ""
    },

    {
        id: 4,
        title: "Maintenance corrective",
        static: " La maintenance corrective s'occupe de prévenir et éventuellement résoudre les problèmes que votre site peut rencontrer. " +
        "En effet, à sa création, le site bénéficie d'une période de garantie de six mois. " +
        "Il a été développé avec les normes de sécurité en vigueur et les méthodes contemporaines. Mais le monde " +
        "numérique est en perpétuelle évolution : les méthodes changent, les serveurs se mettent à jour, les pirates redoublent d'ingénuosité... Pour jouer son rôle " +
        "efficacement, le site doit être suivi régulièrement. ",
        description: [
            {
                id: 0,
                title: "",
                category: "desc-row",
                infos: "",
                price: [
                    { id: 0, title: "Site statique", category: "price-row", label: "À partir de 30€ HT / mois" },
                    { id: 1, title: "Site dynamique", category: "price-row", label: "À partir de 60€ HT / mois" },
                    { id: 2, title: "Application web", category: "price-row", label: "Sur devis" },
                ]
            }
        ],
        icon: ""
    },
    {
        id: 5,
        title: "Maintenance évolutive",
        static: "Les packs de la maintenance évolutive sont destinés aux entreprises souhaitant un suivi régulier et personnalisé de leur site. " +
        "Ils sont \"tout compris\"",
        description: [
            {
                id: 0,
                title: "",
                category: "desc-row",
                infos: "",
                price: [
                    { id: 0, title: "Site statique", category: "price-row", label: "À partir de 150€ HT / mois" },
                    { id: 1, title: "Site dynamique", category: "price-row", label: "À partir de 300€ HT / mois" },
                    { id: 2, title: "Application web", category: "price-row", label: "Sur devis" },
                ]
            }
        ],
        icon: ""
    }
]

export const servicesMaintenance = [
    {
        id: 0,
        title: "Maintenance",
        description: [
            {
                id: 0,
                title: "Maintenance préventive",
                category: "desc-row",
                infos: "La maintenance préventive s'intéresse aux mises à jour. " +
                    "C'est elle qui permet à votre site de durer dans le temps. " +
                    "Les mises à jour sont essentielles et doivent se faire régulièrement, par simple souci de sécurité. Ces packs " +
                    " sont les entrées de gamme en matière de service après vente." +
                    " La maintenance préventive inclut les modifications peu importantes telles que les actualisations de contenu textuel, dans la limite d'une modification par semestre.",
                price:
                    [
                        { id: 0, title: "Site statique", category: "price-row", label: "À partir de 15€ HT / mois" },
                        { id: 1, title: "Site dynamique", category: "price-row", label: "À partir de 30€ HT / mois" },
                        { id: 2, title: "Application web", category: "price-row", label: "À partir de 50€ HT / mois" },
                    ]

            },
            {
                id: 1,
                title: "Maintenance corrective",
                category: "desc-row",
                infos: " La maintenance corrective s'occupe de prévenir et éventuellement résoudre les problèmes que votre site peut rencontrer. " +
                    "En effet, à sa création, le site bénéficie d'une période de garantie de six mois. " +
                    "Il a été développé avec les normes de sécurité en vigueur et les méthodes contemporaines. Mais le monde " +
                    "numérique est en perpétuelle évolution : les méthodes changent, les serveurs se mettent à jour, les pirates redoublent d'ingénuosité... Pour jouer son rôle " +
                    "efficacement, le site doit être suivi régulièrement. ",
                price: [
                    { id: 0, title: "Site statique", category: "price-row", label: "À partir de 30€ HT / mois" },
                    { id: 1, title: "Site dynamique", category: "price-row", label: "À partir de 60€ HT / mois" },
                    { id: 2, title: "Application web", category: "price-row", label: "À partir de 100€ HT / mois" },
                ]
            },
            {
                id: 2,
                title: "Maintenance évolutive",
                category: "desc-row",
                infos: "Les packs de la maintenance évolutive sont destinés aux entreprises souhaitant un suivi régulier et personnalisé de leur site. " +
                    "Ils sont \"tout compris\"",
                price: [
                    { id: 0, title: "Site statique", category: "price-row", label: "À partir de 150€ HT / mois" },
                    { id: 1, title: "Site dynamique", category: "price-row", label: "À partir de 300€ HT / mois" },
                    { id: 2, title: "Application web", category: "price-row", label: "À partir de 500€ HT / mois" },
                ]
            }
        ],
        icon: ""
    }
]

export const publicDetails = [
    {
        id: 0,
        title: "Agence de Communication",
        description: "Vous souhaitez vous affranchir des CMS aux temps de chargement et d'exécution fastidieux." +
            "Vous désirez diversifier vos services et proposer à vos clients des plateformes (e-commerce, réservation en ligne...) personnalisées, fiables et rapides",
        icon: ""
    },
    {
        id: 1,
        title: "ESN | Agence Web",
        description: "Vous connaissez un surplux d'activité et avez besoin d'une personne polyvalente",

        icon: ""
    },
    {
        id: 2,
        title: "PME",
        description: "Votre site est le reflet de votre entreprise. " +
            " Vous souhaitez développer votre présence en ligne ou proposer de nouvelles fonctionnalités à vos clients? Créer de nouvelles rubriques? Faire évoluer votre site vers une technologie plus réactive? "
        ,
        icon: ""
    }
]

export const prestationDetails = [
    {
        id: 0,
        title: "UX/UI - Cahier des charges",
        description: "Étude fonctionnelle pour une solution adaptée à vos besoins et à l'utilisateur",
        icon: "",
        background: Ux,
    },
    {
        id: 1,
        title: "Maquettage",
        description: "Prototypage, Design et Maquette fonctionnelle pour partir dans la même direction",
        icon: "",
        background: cahierDesChargesImg,
    },
    {
        id: 2,
        title: "Médias",
        description: "Envie de vous différencier ? Optez pour la 3D, le flat design ou le rendu vectoriel !"
        ,
        icon: "",
        background: media
    },
    {
        id: 3,
        title: "Animations",
        description: "Codées ou pré-calculées, les animations donnent de la personnalité à votre site et attirent l'attention sur le contenu !",
        icon: "",
        background: anims
    },
    {
        id: 4,
        title: "Dévelop- pement FrontEnd",
        description: "Pour un site léger et réactif, des fonctionnalités ciblées à vos besoins.",
        icon: "",
        background: devFront
    },
    {
        id: 5,
        title: "Dévelop- pement BackEnd",
        description: " Le code côté serveur intervient partout où la sécurité est nécessaire : stockage de données, paiement en ligne, compte utilisateur, formulaire de contact..."

        ,
        icon: "",
        background: devBack
    },
    {
        id: 6,
        title: "Maintenance",
        description: " Et après ? " +
            " Pour éviter les piratages et montrer votre présence, gardez votre solution à jour et adaptez votre contenu à l'actualité. "
        ,
        icon: "",
        background: maintenance
    },
    {
        id: 7,
        title: "Un coup de mains ?",
        description: " Vous êtes une SSII ou une agence de communication. " +
            " Vous avez besoin d'une personne polyvalente et motivée ? "
        ,
        icon: "",
        background: plus
    }
]


export const logos = [
    { id: 0, title: "Ama", img: logoAma },
    { id: 1, title: "Nomattitude", img: logoNomattitude },
    { id: 2, title: "Mairie de Savigny-le-Temple", img: logoSlt },
    { id: 3, title: "Bulkypix", img: logoBulkypix },
    { id: 4, title: "Cité des Sciences", img: logoCiteDesSciences },
    { id: 5, title: "Les Écuries d'Ulysse", img: logoUlysse },
    { id: 6, title: "InteratifActory", img: logoIf },
    { id: 7, title: "Ginger Studio devenu Gimagin", img: logoGimagin },
    { id: 8, title: "Vivendi Games Mobile", img: logoVivendi },
    { id: 9, title: "Pulsanim", img: logoPulsanim },
    { id: 10, title: "Coktel", img: logoCoktel },
    { id: 11, title: "Veolia", img: logoVeolia },
    { id: 12, title: "Écurie du chateau", img: logoLaura },
    { id: 13, title: "Ingrid EquiLys", img: logoIngrid }

]


export const navData = [
    {
        id: 0,
        label: "La Toile d'en Bouyssou",
        component: <Home gallery={gallery} domains={domains} />
    },
    {
        id: 1,
        label: "Portfolio",
        component: <Portfolio />
    },
    {
        id: 2,
        label: "À la une !",
        component: <DevWeb />

    },
    {
        id: 3,
        label: "Compétences",
        component: <Prestations />
    },
    {
        id: 4,
        label: "Services et tarifs",
        component: <Services />
    },

    {
        id: 5,
        label: "À propos",
        component: <About />
    }

]

export const cards = [
    {
        id: 0,
        title: "Gérez vos rendez-vous en ligne",
        info: "...Simplement",
        btn: "Accéder à la démo"
    },



];
