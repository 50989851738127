
import { DrapsWrapper } from "../common";
import Draps from "./Draps";
import {TheContext} from "../context/MyContext";
import { useContext } from "react";



interface Services {
    
    id: number,
    title: string,
    static: string,
    description: ServicesDescription[],
    icon: string
}
interface ServicesDescription {
    id: number,
    title: string,
    category: string,
    infos: string,
    price: ServicesPrice[]
}
interface ServicesPrice {
    id: number,
    title: string,
    category: string,
    label: string
}

export default function ServiceArticle(props: 
    { 
        services: Services[] | null | undefined, 
        maintenance: Services[] | null | undefined,         
        servicesInfo: ({height: number | undefined, bottom: number | undefined})[]|undefined, 
        savRef: ({height: number | undefined, bottom: number | undefined})[]|undefined,
        startingSav: ({height: number | undefined, bottom: number | undefined})[]|undefined,
        startingServices: ({height: number | undefined, bottom: number | undefined})[]|undefined
    }
   
    
    ) {
        
   const myContext = useContext(TheContext);

    
    return (
        <>
        <div className="prez-services">
        Un site internet ou une application web, c'est un investissement à long terme. 
        <p>À ce titre, il y a une phase de création, puis
        une phase de maintenance pour les mises à jours de contenu et de fonctionnalités.</p>
        <p>On peut classifier les sites en trois groupes ayant chacun un rôle précis :</p>
        <p className="p-services-details">Les sites statiques - catalogues en ligne ou sites vitrine</p>
        <p>Ils sont appelés ainsi car ils ne possèdent généralement aucune animation et surtout ils ne répondent à aucune action de l'utilisateur.</p>
        <p> On peut néanmoins y intégrer des animations, mais elles seront automatiques (animations de vie).</p>
        
        <p className="p-services-details">Les sites dynamiques</p>
        <p>Ils répondent aux actions de l'utilisateur et possèdent un formulaire de contact. </p>
        <p>Ces sites sont vivants et font participer le visiteur (scroll, clic, saisie de données...). Leurs animations sont donc plus pertinentes pour l'internaute et ils intègrent des widgets (facebook, youtube ). Le contact avec votre clientèle est professionnel. En revanche, ils demandent plus d'investissement tant en création qu'en maintenance, car ils utilisent des services externes (captcha, mailgun) et un serveur.</p>
        
        <p className="p-services-details">Les applications web | e-commerce | plateformes de gestion de site | réservations en ligne...</p>
        <p>Ce sont des sites dynamiques élaborés. Ils intègrent un serveur et une base de données.
        </p>
        <p>Comme ils répondent à des besoins spécifiques, ils font l'objet d'une étude tarifaire particulière.</p>
        <div className ="prez-details">
            <h3>Réalisation minimum pour un site statique en création</h3> 
        <p>- Un composant menu pour naviguer entre les pages,</p>
        <p>- Un composant par page,</p>
        <p>- Un composant pour les mentions légales et cookies,</p>
        <p>- Un composant par animation supplémentaire,</p>
        <p>- Quatre composants minimum pour un formulaire de contact (création d'un serveur, vérification anti-spam des adresses mail, utilisation d'un captcha et enregistrement auprès d'un service d'envoi de mails).</p>
        <h3>Maintenance minimum pour un site statique</h3> 
        <p>Maintenance préventive pour les mises à jour.
        </p>
        </div>
        </div>
            <div className="maintenance-wrapper" id="services">
                {props.services && props.services.map(aService =>                
                <DrapsWrapper height={myContext.arrowIsClicked &&  myContext.arrowIsClicked[aService.id] && myContext.arrowIsClicked[aService.id].isClicked ? "fit-content":"25em"} key={aService.id} id={aService.id + '-ref'}>
                <Draps                    
                    total= {6}              
                    startingPosition = {props.startingServices && props.startingServices[aService.id].height}
                    id={aService.id}
                    aService={aService}
                    refId={aService.id + '-ref'}                    
                    serviceBottom={props.servicesInfo && props.servicesInfo[aService.id].bottom}
                    serviceHeight={props.servicesInfo && props.servicesInfo[aService.id].height}
                >                    
                    </Draps>
             
                    </DrapsWrapper>
                )}

            </div>
            <div className="maintenance-wrapper" id="services">
            {props.maintenance && props.maintenance.map(aService =>
                <DrapsWrapper height={myContext.arrowIsClicked &&  myContext.arrowIsClicked[aService.id] && myContext.arrowIsClicked[aService.id].isClicked ? "fit-content":"45em"} key={aService.id + "-maintenance-ref"} id={aService.id + '-maintenance-ref'}>
                    <Draps                    
                    total= {6}
                    startingPosition = {props.startingSav && props.startingSav[aService.id].height}
                    id={aService.id.toString()+'-maintenance-'}
                    aService={aService}
                    refId={aService.id + '-maintenance-ref'}                    
                    serviceBottom={props.savRef && props.savRef[aService.id].bottom}
                    serviceHeight={props.savRef && props.savRef[aService.id].height}
                >            
                </Draps>             
             </DrapsWrapper>
            )}
               </div>
        </>
    )
}
