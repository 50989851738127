import { ButtonPosition, ImgSection } from '../common';
import '../styles/App.css';
import CurvingTitle from './CurvingTitle';




export default function AnimatedButton({...props}){
    return (
        <div className="btn-mask">
             <h2 className='letters-position'>
                <CurvingTitle title={props.title}/>
            </h2>
            <ButtonPosition
                isActiv={props.clickedId === props.btnId} 
                isOld={props.oldId === props.btnId}
                isSame={props.sameId === props.btnId}
                onPointerEnter={(e: MouseEvent)=>props.onHover && props.onHover(e, props.btnId)}
                onPointerOut={(e: MouseEvent)=>props.onLeave && props.onLeave(e)}
                onClick={ ()=>{
                    props.onClick()          
                }}   
            >
               
                {props.images.map((image: string, key: number)=>
                <ImgSection
                    key = {key}
                    isHover={props.hoveredId === props.btnId}
                    className={props.activId === key ? "image-ok" : "image-down"}
                    src={image}
                    alt={props.title}>
                </ImgSection>
                )}
                

            
            </ButtonPosition>
           
        </div>
    
    )
}

   


