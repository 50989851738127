import { forwardRef } from 'react'


import "../styles/App.css";
import "../styles/PostIt.css";






export const ContactRef = forwardRef <HTMLButtonElement | null >((props, ref) =>{
    
return (
    <>
    <button  
    id='6-btn' 
    className="section-button"  
    value="" 
    ref={ref}>
    </button>
        <h2>Formulaire de contact</h2>
        <span>Pour me joindre</span>
        <p>Vous pouvez suivre le menu <span className='menu-span'>À propos</span>, vous trouverez mes coordonnées dans <h2> infos</h2></p>
        <p><span className='menu-span'> ou</span> utiliser le formulaire ci-dessous</p>
   
    

</>
    )});