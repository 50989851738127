import {ShowHideSide, SideNavKey, SideNavLi} from "../common/index";
import { useEffect} from 'react';
import SideIconF from "../components/SideIconF";
import SideIconLn from "../components/SideIconLn";
import {SideIconContact} from "../components/SideIconContact";
import PlayKey from "./PlayKey";
import { TheContext } from '../context/MyContext';
import { useContext } from 'react';


interface SideProps {
    onContactClick(id: number): void;
    phoneNav: boolean;
}





const SideNav: React.FC <SideProps>= ({onContactClick, phoneNav}) =>{
    
    const myContext = useContext(TheContext);


    const handleKeyClick=()=>{
        console.log("on passe dans handlebowlclick");
        // toggleplay house anim
        myContext.letsPlayPaperHouse ? myContext.setLetsPlayPaperHouse(false) : myContext.setLetsPlayPaperHouse(true);
    }  

    useEffect(() => {
       
        
    }, [])
    
    const props = {color: "#ED4353", onClick: onContactClick, phoneNav: phoneNav}
   
    return(
                <div className="side-nav-wrapper">  
                <ShowHideSide propStyled={phoneNav}>
                    <SideNavKey>
                        <PlayKey onKeyClick={handleKeyClick}/>
                    </SideNavKey>
                    <div className="side-nav">
                    <SideNavLi><SideIconContact {...props}/></SideNavLi>
                    <SideNavLi>
                        <a href="https://www.facebook.com/leWebDansLeTarn" target="_blank">
                            <SideIconF {...props}/>
                        </a>
                    </SideNavLi>
                    <SideNavLi>
                        <a href="https://www.linkedin.com/in/alinebrand" target="_blank">
                            <SideIconLn {...props}/>
                        </a>
                    </SideNavLi>
                    </div>
                
                </ShowHideSide>
                </div>
        
    )
}
export default SideNav;


