import React, { useEffect, useState } from 'react'
import { AnimCard } from '../common'

interface AnimatedCardProps{
    animKey: number,
    item: AnimatedItem,
    letsAnimCards: boolean
}
interface AnimatedItem{
    id: number,
    title: string, 
    info1: string,
    info2?: string
}

   

export default function AnimatedCard(props: AnimatedCardProps) {

    

    useEffect(() => {
       
    }, [])
    
  
  return (
    
    <AnimCard id={"dev-card"+props.animKey} letsAnimCards={props.letsAnimCards} className="dev-card" key={props.animKey+"-info"}>
      <h2 key={props.animKey+"projectTitle"}>{props.item.title}</h2>
      <p key={props.animKey+"info1"}>{props.item.info1}</p>
      <p key={props.animKey+"info2"}>{props.item.info2 && props.item.info2}</p>
    </AnimCard>
  )
}
