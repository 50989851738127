import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { ImgSection, InfoDiv } from '../common';
import "../styles/App.css";
import { InfoAnim } from './InfoAnim';
const skillsImports = require.context("../../public/Anims/Skills/", true, /\.png|jpe?g$/);


interface AnimatedSection{        
    id: number,
    isToLaunch: boolean,
    images: ImageProps[],
    title: string
}
interface ImageProps{
    id: number,
    url: string,
   
}
interface PlayRewindProps{
    isHover:boolean,
    section: AnimatedSection,
    images: string[],
    background: string | null | undefined,
    disabled: boolean
}

export default function PlayRewind({...props}): ReactElement<PlayRewindProps> {
        

    return (
        
        <>
            
            {props.imgTable && props.imgTable.map((image: ImageProps, key: number)=>(
               <InfoDiv key={'info-div' + image.id} isActiv={props.activId === key}>
                <ImgSection
                    isPlaying={props.activId <= props.imgTable.length}
                    key={"img-section"+ key + "-img"}
                    isHover={props.isHover}
                    className={props.className}
                    src={image.url}
                    onClick={props.onClick}
                    />
                {props.background && <img className="bckg-compass" src={props.background}></img>}
                </InfoDiv>
            ))}
        </>
       
    )
}
