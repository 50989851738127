
import Nav from "./components/Nav";
import firstPaperHouse from "./images/loadingImages/HouseAnim_0000.png";
import { Section } from "./pages/Section";
import { useState, useEffect, useRef, useContext } from 'react';
import { navData } from "./data/data";
import SideNav from './components/SideNav';
import { ContactRef } from './pages/ContactRef';
import { ContactBlock, StyledApp } from './common';
import PostIt from './components/PostIt';
import CompassBtn from './components/CompasBtn';
import { TheProvider, TheContext } from "./context/MyContext";
import MyAxiosClass from "./data/phpApi";
import ReactDOM from 'react-dom/client';

/**
 * ! : L'adresse du serveur est ebwebapi.alienshareonline.fr pour des raisons
 * de ssl. il pointe bien sur le dossier ebweb/api du serveur
 */
declare global {
    interface Window {
      __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
    }
  }

interface NavProps {
    onClick(id: number): void;
    handleNavPhoneClick(): void;
    phoneNav: boolean;
    activSection: number;
    scrolledSection: number;
}
interface SideNavProps {
    onContactClick(id: number): void;
    phoneNav: boolean;
}
interface ClientKey {
    id: string;
    key: string;
}

interface Title {
    id: number;
    isVisible: boolean;
}

interface NavDataElement {
    id: number;
    component: JSX.Element;
    label: string;
}
interface SectionDomInfos {
    sectionId: number;
    height: number;
    top: number;
}

function recupDivHeight(sectionId: number) {
    let divHeight = 50;
    const myDiv = document.getElementById("section-" + sectionId);
    const getDivHeight = myDiv?.getBoundingClientRect().height;
    divHeight = getDivHeight !== undefined ? getDivHeight : 50;
    return divHeight;
}




export default function App() {

    const sectionRefs: (HTMLButtonElement | null)[] = [];
    const [phoneNav, setPhoneNav] = useState(false);
    const [isActiv, setIsActiv] = useState(false);
    const [playAnim, setPlayAnim] = useState(false); // play/rewind
    // timers
    const timerOpenForm = useRef<any>(null);
    const timerPaperHouse = useRef<any>(null);
    const [captchaCltKey, setCaptchaCltKey] = useState<string>("");
    const [activSection, setActivSection] = useState<number>(0);
    const [visibleTitles, setVisibleTitles] = useState<(Title | undefined)[]>(formatData());
    const [visibleTitle, setVisibleTitle] = useState<number>(0);
    const [cardsVisible, setCardsVisible] = useState(false);
    const [blockIsScrollingX, setBlockIsScrollingX] = useState(false);
    const [servicesAreVisible, setServicesAreVisible] = useState(false);
    const [servicesInfo, setServicesInfo] = useState<({ height: number | undefined, bottom: number | undefined })[] | []>();
    const [savRef, setSavRef] = useState<({ height: number | undefined, bottom: number | undefined })[] | []>();
    const [startingServices, setStartingServices] = useState<({ height: number | undefined, bottom: number | undefined })[] | []>();
    const [startingSav, setStartingSav] = useState<({ height: number | undefined, bottom: number | undefined })[] | []>();
    const compassImports = require.context("../public/Anims/Compass/", true, /\.png|jpe?g$/);
    const CompassPath = compassImports.keys().map(myImport => myImport.replace("./", "Anims/Compass/"));

    const houseImports = require.context("../public/Anims/PaperHouse/", true, /\.png|jpe?g$/);
    const paperHousePath = houseImports.keys().map(myImport => myImport.replace("./", "Anims/PaperHouse/"));

    const [paperHouseIsLoaded, setPaperHouseIsLoaded] = useState(false);

    // window dimensions
    const windowHeight = window.innerHeight;
    const myContext = useContext(TheContext);
    const [sectionDomInfos, setSectionDomInfos] = useState<SectionDomInfos[] | []>([]);

    /* CompoDidUpdate */
    useEffect(() => {
        // paperHouse

        if (paperHousePath.length === 41) { //nb frames for animation
            setPaperHouseIsLoaded(true);

        }
    }, [paperHousePath.length])
    //compodidMount
    useEffect(() => {
        const disableReactDevTools=()=>{
       
            if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
              for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
                window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
              }
            }
          }
          const disableConsoleLog=()=>{
            console.log = function () {};
          }
        // ICI disable console.log and react devtools if prod
        
       if (!MyAxiosClass.getClientLocation().includes("http://localhost")){
            disableReactDevTools();
            disableConsoleLog();
        }

        // fit section height to content       
        const sectionDomInfos = calculateSectionHeight();
        setSectionDomInfos(sectionDomInfos);
        console.log("les infos des sections : ", sectionDomInfos);
        // Déclenche l'apparition de l'intro paperHouse - Si les images
        // ne sont pas load, une image fixe s'affiche jusqu'au chargement
        // complet du tableau d'images -
        /* timerPaperHouse.current = setTimeout(() => {
            myContext.setLetsPlayPaperHouse(true);
        }, 100); */



        initFromApi();
        async function initFromApi() {
            try {
                const myClientKey = await MyAxiosClass.getClientApiKey();
                if (myClientKey !== undefined) {
                    if (myClientKey.myKey) {                       
                        setCaptchaCltKey(myClientKey.myKey);
                    } else { // clientKey.error
                        console.log("erreur key form index : " + myClientKey.error);
                    }


                }
            } catch (error) {
                console.log("on ne récupère pas de clé du serveur, bbouuuhh")
            }
    }
       
    window.addEventListener('scroll', checkIndexScroll, false);
    window.addEventListener('resize', checkIndexScroll, false);
    return () => {
        if (timerPaperHouse.current) {
            clearTimeout(timerPaperHouse.current);
        }
        window.removeEventListener('scroll', checkIndexScroll, false);
        window.removeEventListener('resize', checkIndexScroll, false);
    }
}, []);


function formatData() {

    let tempItems = navData.map(item => {
        let id = item.id;
        let isVisible = false;
        // créé l'objet room à partir de la propriété fields de item, y ajoute images
        // qui écrase le tableau images des data avec notre let images
        let isVisibleTitle = { id, isVisible };
        // pour chaque item on retourne une chambre
        return isVisibleTitle;
    })
    // on retourne une liste temporaire d'items mis en forme
    return tempItems
}


function formatVisibleTitles() {
    let titles;
    titles = visibleTitles.map((title: Title | undefined) => {
        if (title) {
            const aTitle = document.getElementById(title.id.toString() + '-btn');

            if (aTitle) {
                // la div
                const topTitle = aTitle.getBoundingClientRect().top;
                const bottomTitle = aTitle.getBoundingClientRect().bottom;
                const isVisible = bottomTitle > 0 && topTitle < windowHeight;
                let titleToUpdate: Title;
                if (isVisible) {
                    titleToUpdate = { ...title, id: title.id, isVisible: true };
                    if (titleToUpdate.id === title.id) {
                        return titleToUpdate;
                    }
                } else {
                    titleToUpdate = { ...title, id: title.id, isVisible: false };
                    if (titleToUpdate.id === title.id) {
                        return titleToUpdate;
                    }
                }
            }
        }
    }
    );
    return titles;

}
function handleCardsVisibility(cardDiv: HTMLElement) {
    const isVisibleDiv = formatVisibleDiv(cardDiv);
    if (isVisibleDiv) {
        setCardsVisible(true);
    } else {
        setCardsVisible(false);
    }
}

function formatVisibleDiv(theDiv: HTMLElement) {

    // la div
    const topTitle = theDiv.getBoundingClientRect().top;
    const bottomTitle = theDiv.getBoundingClientRect().bottom;
    const isVisible = bottomTitle > 0 && topTitle < windowHeight;

    return isVisible;
}

function calculateSectionHeight(): SectionDomInfos[] {

    let divToAdd = { height: 0 };
    const datas = navData.map((value: NavDataElement) => {
        const sectionHeight = recupDivHeight(value.id);
        switch (value.id) {
            case 2:
                const projectDetailsDiv = document.getElementById("cards-for-anim");
                const persCardsDiv = document.getElementById("cards-container-id");
                const projDetailsHeight = projectDetailsDiv?.getBoundingClientRect().height;
                const persCardsHeight = persCardsDiv?.getBoundingClientRect().height;
                if (projDetailsHeight && persCardsHeight) {
                    divToAdd.height = projDetailsHeight + persCardsHeight;
                }
                break;
            case 3:
                const HorizontalScrollSnapDiv = document.getElementById("scroll-block");
                const HorScrollDivHeight = HorizontalScrollSnapDiv?.getBoundingClientRect().height;
                if (HorScrollDivHeight) {
                    divToAdd.height = HorScrollDivHeight;
                }
                break;

            default:
                divToAdd.height = 0;
                break;
        }
        const theTop = recupDivTop(value.id);

        return { sectionId: value.id, height: divToAdd.height + sectionHeight, top: theTop };
    })
    return datas;

}
const recupDivTop = (sectionId: number) => {
    let divTop = 50;
    const myDiv = document.getElementById("section-" + sectionId);
    const getDivTop = myDiv?.getBoundingClientRect().top;
    divTop = getDivTop !== undefined ? getDivTop : 0;
    return divTop;
}

const checkIndexScroll = () => {
    // check if services draps are visible
   /*  const myServices = document.getElementById("services");
    if (myServices) {
        const servicesAreVisible = formatVisibleDiv(myServices);
        setServicesAreVisible(servicesAreVisible);        
        const refs = servicesData.map((service: any) => {
            const drapsRefPos = document.getElementById(service.id + '-ref')?.getBoundingClientRect().bottom;
            const drapsRefHeight = document.getElementById(service.id + '-ref')?.getBoundingClientRect().height;
            return { bottom: drapsRefPos, height: drapsRefHeight };
        });
        if (refs)
            setServicesInfo(refs);
        const savRef = savData.map((service: any) => {
            const drapsRefPos = document.getElementById(service.id + '-maintenance-ref')?.getBoundingClientRect().bottom;
            const drapsRefHeight = document.getElementById(service.id + '-maintenance-ref')?.getBoundingClientRect().height;
            return { bottom: drapsRefPos, height: drapsRefHeight };
        });
        if (savRef) {
            setSavRef(savRef);
        }
/*
        const startingServices = servicesData.map((service: any) => {
            const drapsRefPos = document.getElementById(service.id + '-start')?.getBoundingClientRect().bottom;
            const drapsRefHeight = document.getElementById(service.id + '-start')?.getBoundingClientRect().height;
            return { bottom: drapsRefPos, height: drapsRefHeight };
        });
        if (startingServices) setStartingServices(startingServices);
        const startingSav = savData.map((service: any) => {
            const drapsRefPos = document.getElementById(service.id + '-maintenance-' + '-start')?.getBoundingClientRect().bottom;
            const drapsRefHeight = document.getElementById(service.id + '-maintenance-' + '-start')?.getBoundingClientRect().height;
            return { bottom: drapsRefPos, height: drapsRefHeight };
        });
        if (startingSav) setStartingSav(startingSav);
        if (savRef) {
            setSavRef(savRef);
        }



    } */

    //OnscrollY: animation des cartes 
    const myCards = document.getElementById("cards-for-anim");
    //Prestations - gestion des animations onscrollX
    const scrolledBlock = document.getElementById("scroll-block");
    // Animation des titres onscrollY
    const newTitles = formatVisibleTitles();
    setVisibleTitles(newTitles);
    // seulement le titre visible
    const visibleTitle = newTitles.find((aTitle: Title | undefined)=>(
         aTitle && aTitle.isVisible)
    );
    if (visibleTitle) {setVisibleTitle(visibleTitle.id);
    }
    // Animation des cartes de "à la une" onscrollY
    if (myCards) {
        handleCardsVisibility(myCards);
    }
    // Animation des cartes des prestations + anim bottle on scrollX
    if (scrolledBlock && window.scrollX) {
        setBlockIsScrollingX(true);
    } else {
        setBlockIsScrollingX(false);
    }
}

const handleNavPhoneClick = () => {
    phoneNav === true ? setPhoneNav(false) : setPhoneNav(true);
}

const ToggleFormOpen = () => {
    // lance l'anim du compas
    myContext.setLetsGo(true);
    playAnim ? setPlayAnim(false) : setPlayAnim(true);
    // ouvre le form
    timerOpenForm.current = setTimeout(
        function () {
            isActiv ? setIsActiv(false) : setIsActiv(true);
        }, 500);
}

const onContactClick = (id: number) => {
    console.log("ref cliquée : ", id);
    let currentSection: HTMLButtonElement | null;
    if (sectionRefs) {
        currentSection = sectionRefs[id];
        if (currentSection) {
            currentSection.focus();
            if (id === 6) {
                smoothScroll("6");
                myContext.setLetsGo(true);
                setIsActiv(true);
                setPlayAnim(true);
                // form contact s'ouvre
                console.log("isactiv contact clic : ", playAnim);
            }

        }
    }
}




function smoothScroll(id: string) {

    const sectionToGo = document.getElementById(id + '-btn');
    if (sectionToGo !== null) {
        console.log(sectionToGo);
        sectionToGo.scrollIntoView({
            block: "start",
            behavior: 'smooth',
            inline: 'center'
        });
    }
}


const onClick = (pActivRef: number) => {
    setActivSection(pActivRef);
    let currentSection: HTMLButtonElement | null;
    if (sectionRefs) {
        currentSection = sectionRefs[pActivRef];
        if (currentSection) {
            smoothScroll(pActivRef.toString());
            //currentSection.focus();                
            setIsActiv(false); // form contact se ferme
            phoneNav === true && setPhoneNav(false)
            // si on est en phone nav on femre le menu au clic sur une ligne
        }
    }


}

const props: NavProps = { onClick, handleNavPhoneClick, phoneNav: phoneNav, activSection, scrolledSection: visibleTitle };
const sideNavProps: SideNavProps = { onContactClick, phoneNav: phoneNav }


return (

    <>
        <Nav {...props} />
        {/*  Navigation sections */}
        <SideNav {...sideNavProps} />
        <div className="App" id="app-ref">          
            <div className="section-wrapper">
                {navData.map(section =>
                    <Section
                        startingSav={startingSav}
                        startingServices={startingServices}
                        servicesInfo={servicesInfo}
                        savRef={savRef}
                        servicesAreVisible={servicesAreVisible}
                        /* letsAnimHouse={myContext.letsPlayPaperHouse} */
                        paperHouseIsLoaded={paperHouseIsLoaded}
                        firstPaperHouse={firstPaperHouse}
                        paperHousePath={paperHousePath}
                        topPosition={sectionDomInfos && sectionDomInfos[section.id] && sectionDomInfos[section.id].top}
                        divHeight={sectionDomInfos && sectionDomInfos[section.id] && sectionDomInfos[section.id].height}
                        blockIsScrollingX={blockIsScrollingX}
                        cardsVisible={cardsVisible}
                        titleToAnim={visibleTitles[section.id]}
                        ref={sectionRef => sectionRefs[section.id] = sectionRef}
                        title={section.label}
                        key={section.id}
                        id={section.id}
                        component={section.component}
                        phoneNav={props.phoneNav}
                        activSection={activSection}
                    />
                )}
                <section className="contact-section">
                    <ContactRef
                        ref={sectionRef => sectionRefs[6] = sectionRef} />
                    <div className="compass">
                        <CompassBtn isActiv={playAnim} images={CompassPath} width="150px" height="150px" className="compass-btn" onClick={() => { ToggleFormOpen() }} />
                        {/*  <CompassBtn images={CompassPath} width="150px" height="150px" className="compass-btn" onClick={handleCompassClick}/>  */}                            <ContactBlock marginTop="2em" blockWidth="80%" isActivBlock={isActiv}>
                            <PostIt
                                cltApiKey={captchaCltKey}                               
                                initialMessage="Taille maxi : 500 caractères"
                                index={0}
                                initialContent="Merci de laisser votre message et vos coordonnées" />
                        </ContactBlock>
                    </div>
                </section>
            </div>
        </div>
    </>
)
}
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

root.render(
    <TheProvider>
        <App />
    </TheProvider>
    

)