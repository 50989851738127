import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { DrapsContainer, RowOpacity } from '../common'
import { TheContext } from '../context/MyContext';
import { Price } from './Price';


interface ServicesDescription {
    id: number,
    title: string,
    infos: string,
    price: ServicesPrice[]
}
interface ServicesPrice {
    id: number,
    category: string,
    title: string,
    label: string
}
interface RowVisibility {
    id: string | undefined,
    isVisible: boolean | undefined
}
const drapInit = 100;


export default function Draps({ ...props }) {
    
    const [pointerDownPrice, setPointerPriceDown] = useState(false);
    const [letsGo, setLetsGo] = useState<boolean>(false);
    const [arrowVisibility, setArrowVisibility] = useState(true);
    const [rowsVisibility, setRowsVisibility] = useState<({ id: string; isVisible: boolean; } | undefined)[]>([]);
    
    const myContext = useContext(TheContext);
   /*  const [state, dispatch] = useReducer(reducer, intialState); */

  /*   function reducer(state: {height: number}, action: {type: string, value: number; }) {
        let myNewValue=0;        
        switch (action.type) {
            case 'isLoading':
            myNewValue= action.value;
            break;      
           case 'pointerup':
            myNewValue = action.value;
            break;
           case 'pointerdown':
            myNewValue = action.value;
            break;
            case 'clicked':
            myNewValue = props.serviceHeight;
            break;
           case 'pointermove':
               const updatedPosition = action.value + state.height;
                if (updatedPosition <= props.serviceHeight && updatedPosition > intialState.height) {
                    myNewValue =  updatedPosition;
                }
                else if (updatedPosition > props.serviceHeight) {               
                    myNewValue = props.serviceHeight;
                } else {
                    myNewValue =  intialState.height;
                    setArrowVisibility(true);
                }
                break;     
            
           default:
               break;
       }
          return {height: myNewValue};
        
    } */

    useEffect(() => {
        
        myContext.initArrowSection(props.total);       
        
        window.addEventListener('pointerup', handlePointerUp);
        /* if (props.startingPosition){                     
            dispatch({type: 'isLoading', value: drapInit + props.startingPosition});
        } */
        return () => {
            window.removeEventListener('pointerup', handlePointerUp);
          
        }
    }, []);




    const handlePointerDown = (event: PointerEvent) => {
        myContext.setPointerIsMoving(true);        
        //dispatch({type: 'pointerdown', value: state.height});        
        setLetsGo(true);
        setPointerPriceDown(true);
    }

    function getRowsVisibilityById(domIds: string[], event: React.PointerEvent<HTMLElement>) {
        
        // get position of the current event
        let rect = event.currentTarget?.getBoundingClientRect();
        let y = event.clientY - rect.top;  //y position within the element.       
       
        const visibilities = domIds.map((aDomId: string)=>{
            const theRow = document.getElementById(aDomId);
            const myPosition = theRow?.getBoundingClientRect().y;
            if (myPosition) {               
                if (myPosition - rect.top <= y) {
                    return {id: aDomId, isVisible: true };
            } else {
                return {id: aDomId, isVisible: false };
            }
        }})
       
    return visibilities;
    }
    function getVisibilityBlock(domIds: string[]) {        
        const visibilities = domIds.map((aDomId: string)=>{
            const theRow = document.getElementById(aDomId);
            if (rowsVisibility.find((aRow: any)=>(
                aDomId === aRow.id && aRow.isVisible === true 
            ))){
            return {id: aDomId, isVisible: false };   
        }else{
            return {id: aDomId, isVisible: true }; 
        }
        })
       
    return visibilities;
    }

    function getRowsId(){
        let myArray = new Array();
        const rowsIds = props.aService.description.map((aDescr: ServicesDescription) => {   
            let rowId: any[] = [];
            rowId = [
                props.id.toString() + aDescr.id.toString() + "-title",
                props.id.toString() + aDescr.id.toString() + "-infos"
                
            ]            
            const prices = aDescr.price.map((aPrice: ServicesPrice)=>{
                let rowIdPrice: any[]= [];
                rowIdPrice = [
                    props.id.toString() + aDescr.id.toString() + aPrice.id + "-title",
                    props.id.toString() + aDescr.id.toString() + aPrice.id + "-label"
                ]
                rowId.push(...rowIdPrice);
            })
            myArray.push(...rowId);
            return rowId;
        });
        
        return myArray;
    }

    function getRowsVisibility(event: React.PointerEvent<HTMLElement>) {
        const rowsIds = getRowsId();
        if (rowsIds){
            let visibilities = getRowsVisibilityById(rowsIds, event);              
            return visibilities;
        }
    }

    function getMyVisibility(pId: string){
     
     const foundElt = rowsVisibility && rowsVisibility.find((aRow: any)=>(
         aRow.id.toString() === pId     
     ));
     if (foundElt){
         return foundElt.isVisible;
     }
     
    }
    const togglePriceVisibility=(pId: number)=>{ 
        console.log("id click : ", pId);           
    /*     setArrowVisibility(false);  */
        let rowsIds = getRowsId();    
        let locRowsVisibility = getVisibilityBlock(rowsIds);
        if (locRowsVisibility){           
            myContext.setArrowIsClicked(pId, (myContext.arrowIsClicked[pId].isClicked === true ? false : true));
            setRowsVisibility(locRowsVisibility);            
        }
    }

    function handlePointerMove(event: React.PointerEvent<HTMLElement>) {

        if (letsGo) {
            setArrowVisibility(false);          
            let rowsVisibility = getRowsVisibility(event);
            if (rowsVisibility){
            setRowsVisibility(rowsVisibility);            
            }            
            //dispatch({type: 'pointermove', value: event.movementY});
        }
    }

    function handlePointerUp(event: PointerEvent) {
        setLetsGo(false);
        myContext.setPointerIsMoving(false);
        setPointerPriceDown(false);
    }




    return (
        <>
       
            <div className="service-card-block" key={props.id}>
            <div className="info" id={props.id + "-start"}>
                <h2>{props.aService.title}</h2>
                <div>
                    {props.aService.static}  
                    <div className="price-title"><span>Tarifs</span></div>
                    <DrapsContainer
                        id={props.id}                          
                        onClick={(e: React.PointerEvent)=> togglePriceVisibility(props.aService.id)}
                        onPointerDown={(e: PointerEvent) => handlePointerDown(e)}
                    /*  onPointerMove={(e: React.PointerEvent<HTMLElement>) => handlePointerMove(e)} */
                        onPointerUp={(e: PointerEvent) => handlePointerUp(e)} 
                    >             
           
                    <Price                     
                    arrowVisibility = {arrowVisibility}
                    pointerDown = {pointerDownPrice}                
                    />
                     </DrapsContainer>
                </div>
                
              
            </div>
                <div className="info-text-zone">{props.aService.description.map((aDescr: ServicesDescription) => (
                    <div key={aDescr.id}>                       
                        <RowOpacity
                            id={props.id.toString() + aDescr.id.toString() + "-title"}
                            isVisible={getMyVisibility(props.id.toString() + aDescr.id.toString() + "-title")}
                        >
                            <h2 className="title2">{aDescr.title}</h2>
                        </RowOpacity>
                        <RowOpacity
                            isVisible={getMyVisibility(props.id.toString() + aDescr.id.toString() + "-infos")} id={props.id.toString() + aDescr.id.toString() + "-infos"}>
                            <span>{aDescr.infos}</span>
                        </RowOpacity>
                        <div className="price">
                            {aDescr.price.map((aPrice: ServicesPrice) => (
                                <div key={aPrice.id}> 
                                    <RowOpacity isVisible={getMyVisibility(props.id.toString() + aDescr.id.toString() + aPrice.id.toString() + "-title")} id={props.id.toString() + aDescr.id.toString() + aPrice.id.toString() + "-title"}>
                                        <h2 className="title2-price">{aPrice.title}</h2>
                                    </RowOpacity>                                   
                                    <RowOpacity isVisible={getMyVisibility(props.id.toString() + aDescr.id.toString() + aPrice.id.toString() + "-label")} id={props.id.toString() + aDescr.id.toString() + aPrice.id.toString() + "-label"}>
                                        <h2 className="subTitle2">{aPrice.label}</h2>
                                    </RowOpacity>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                </div>
            </div>
            
           
      
        </>
    )
}
