import { ChangeEvent, FormEvent, } from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import React from 'react';
import MyAxiosClass from '../data/phpApi';


/**
 * 
 * @param {index, initialMessage, initialContent} props 
 * @returns PostIt
 * LocalStorage utilisé pour sauvegarder l'état au rafraichissement
 * 
 */
declare global {
    interface Window {
        grecaptcha: any;
    }
}

let grecaptcha = window.grecaptcha;

interface PostItProps {
    initialMessage: string,
    index: number,
    initialContent: string,
    accessCtrl?: string,
    cltApiKey: string

}



class PostIt extends Component<PostItProps>{

    inputRef: any;
    myTimerRef: any;



    constructor(props: PostItProps) {
        super(props);
        this.inputRef = React.createRef();
        this.myTimerRef = React.createRef();
    }
    state = {
        message: this.props.initialMessage,
        email: "",
        name: "",
        phone: "",
        processing: false,
        isDisable: true,
        compteur: 0,
        error: "Votre message",
        tryingTime: 0,
        googleScore: -1 // init retour google
    }

    async componentDidMount() {

        /*  if (this.props.cltApiKey !== undefined && this.props.cltApiKey !== ""){
             console.log("dans compoMount... no duplicate? cltAipKey : " + this.props.cltApiKey);
             loadReCaptcha(this.props.cltApiKey, this.verifyCallback);
         } */
         //const theTimeOut = 60*2*1000;
        const theTimeOut = 2*1000*60;
      
        const loadScriptByURL = (id: string, url: string, callback: Function) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {

                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }
        // first time
        if (this.props.cltApiKey !== undefined && this.props.cltApiKey !== "") {
            loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${this.props.cltApiKey}`, function () {
                console.log("Script loaded!");
            });
        }else{
            console.log("Script is loading");
        }
        
            //console.log("client key : ", this.props.cltApiKey);
        // load script every 2 minutes
        this.myTimerRef.current = setInterval(() => {
            if (this.props.cltApiKey !== undefined && this.props.cltApiKey !== "") {
                loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${this.props.cltApiKey}`, function () {
                    console.log("Script loaded!");
                });
            }else{
                console.log("Script not loaded");
            }
            }, theTimeOut);    
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
    // first time if cltKey not loaded before
    const loadScriptByURL = (id: string, url: string, callback: Function) => {
        const isScriptExist = document.getElementById(id);

        if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {

                if (callback) callback();
            };
            document.body.appendChild(script);
        }

        if (isScriptExist && callback) callback();
    }
    if (this.props.cltApiKey !== undefined && this.props.cltApiKey !== "" && prevProps.cltApiKey !== this.props.cltApiKey) {
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${this.props.cltApiKey}`, function () {
            console.log("Script loaded!");
        });
    }
    }

    componentWillUnmount() {
        //MyAxiosClass.handleCancelRequest("All requests cancelled before unmount");

        if (this.myTimerRef.current) {            
            clearInterval(this.myTimerRef.current);
        }
    }
    checkEmailAddress(emailAddress: String): boolean {
        let sEmail: string;
        sEmail = emailAddress.toString();
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        //const regExp = new RegExp('^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$');
        if (sEmail.match(mailformat)) {
            return true;
        } else {
            return false;
        }

    }
    checkPhoneNumber(phoneNumber: String): boolean {
        let sPhoneNumber: string;
        sPhoneNumber = phoneNumber.toString();
        var phoneFormat = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
        //const regExp = new RegExp('^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$');
        if (sPhoneNumber.match(phoneFormat)) {
            return true;
        } else {
            return false;
        }

    }
    handleButtonDisable() {
        const { phone, email, name, message } = this.state;
        // check if phone number is good format
        const phoneIsOk = this.checkPhoneNumber(phone);
        // checkEmail
        const mailIsOk = this.checkEmailAddress(email);
        if (phoneIsOk && mailIsOk && name !== "" && message !== "") {
            this.setState({ isDisable: false });
        } else {
            this.setState({ isDisable: true });
        }

    }
    onPostSubmit = async (e: FormEvent<HTMLFormElement>) => {
        let iGoogleRes;
        e.preventDefault();
        // captcha logic
        if (this.props.cltApiKey !== undefined && window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(this.props.cltApiKey, { action: 'submit' }).then((token: string) => {
                    console.log("token", token);
                    try {
                        MyAxiosClass.getGoogleScore(token.toString()).then((googleRes: any) => {
                            if (googleRes.success === false) {
                                this.setState({ processing: false });
                                iGoogleRes = 0.1;
                                this.setState({ error: "Votre message n'a pas pu être envoyé, vous pouvez néamoins trouver mes coordonnées par la section INFOS du menu À PROPOS" });

                            } else { // retour ok
                                if (googleRes.myScore) {
                                    console.log("le score : ", googleRes.myScore);
                                    iGoogleRes = parseFloat(googleRes.myScore); // score is decimal
                                    this.setState({ googleScore: iGoogleRes });
                                    // envoi du mail
                                    const { message, email, name, phone } = this.state;
                                    this.setState({ processing: true });
                                    // check if phone number is good format
                                    const phoneIsOk = this.checkPhoneNumber(phone);
                                    // checkEmail
                                    const mailIsOk = this.checkEmailAddress(email);
                                    // check for captcha
                                    // send to server
                                    if (phoneIsOk && mailIsOk && name !== "" && message !== "") {
                                        if (iGoogleRes > 0.1) {
                                            this.eMailProceed(message, email, name, phone);
                                        } else {
                                            console.log("score captch < 0.1 ou erreur");
                                            this.setState({ processing: false });
                                            this.setState({ error: "Votre message n'a pas pu être envoyé, vous pouvez néanmoins me contacter via la rubrique À PROPOS" });
                                        }
                                    } else {
                                        this.setState({ processing: false });
                                        this.setState({ error: "Merci de vérifier votre saisie, tous les champs sont requis" });

                                    }

                                }
                            }
                        })
                            .catch((reason: any) => {
                                console.log(reason);
                            }
                            )

                    } catch (error) {
                        this.setState({ processing: false });
                        this.setState({ error: "Votre message n'a pas pu être envoyé suite à un incident technique. Vous pouvez néanmoins me contacter en suivant le menu À PROPOS de la section INFOS" });
                        console.log("c'est l'erreur : ", error);

                    }


                });
            });
        }

    }
    async eMailProceed(pMessage: string, pEmail: string, pName: string, pPhone: string) {

        try {
            const res = await MyAxiosClass.sendToMailGun(pEmail, pName, pMessage, pPhone);
            console.log("réponse serveur mail : " , res);
            if (res !== undefined) {
                this.setState({ processing: false });
                if (res.error) {
                    console.log("c'est l'erreur : ", res.error);
                    this.setState({ error: res.error });
                } else {
                    // reset les input
                    this.setState({ name: "" });
                    this.setState({ email: "" });
                    this.setState({ phone: "" });
                    this.setState({ message: "" });
                    console.log("Email envoyé!", res.ok);
                    this.setState({ error: "Votre message a bien été envoyé" });
                }
            }
        } catch (error) {
            console.log("erreur catch try to send mail : ", error)
            this.setState({ error: "Une erreur s'est produite, vous pouvez néanmoins tenter de me contacter par mail ou par téléphone" });
        }


    }
    onTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        this.setState({ error: "Votre message" }); // reset error message
        this.setState({ message: event.target.value });
        this.handleButtonDisable();
        /*set localStorage each time state changes*/
        localStorage.setItem('messagestate' + this.props.index, event.target.value);

    }
    handleTelChange = (e: ChangeEvent<HTMLInputElement>) => {
        /*  this.setState({error: "Votre message"}); */ // reset error message
        e.target.value = e.target.value
            // Remove all non-digits, turn initial 33 into nothing
            .replace(/\D+/, '')
            .replace(/^330?/, '0')
            // Stick to first 10, ignore later digits
            .slice(0, 13)
            // Add a space after any 2-digit group followed by more digits
            .replace(/(\d{2})(?=\d)/g, '$1 ')


    }
    onInputChange(e: ChangeEvent<HTMLInputElement>): void {
        this.setState({ error: "Votre message" }); // reset error message
        console.log("test name with function : " + e.target.value);

        //this.setState({name: e.target.value});
        if (e.target.name === "phone") {
            this.handleTelChange(e);

        }
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.handleButtonDisable())
        const eventName = e.target.name;
        console.log("name : ", eventName);
        console.log("value : ", e.target.value);

        /*set localStorage each time state changes*/


        //localStorage.setItem(eventName, e.target.value);


    }
    onEmailChange(e: ChangeEvent<HTMLInputElement>): void {
        console.log("test email : " + e.target.value);
        this.setState({ email: e.target.value }, () => this.handleButtonDisable());

        localStorage.setItem('emailstate', e.target.value);
    }
    handleMouseEnter = () => {
        this.setState({ message: this.props.initialMessage === this.state.message ? "" : this.state.message });
        this.setState({ error: "Votre message" });
    }

    render() {

        return (
            <>

                <p className="postit">
                    <span className='subTitle2'>{this.props.initialContent}</span>
                </p><form id="post-it" className="postit" onSubmit={(e) => this.onPostSubmit(e)}>
                    <p className="postit-inputs">
                        <label className="contact-label" htmlFor="email">Votre email</label>
                        <input ref={this.inputRef} className="contact-input" type="email" id="email" value={this.state.email} placeholder="Votre mail" onChange={(e: ChangeEvent<HTMLInputElement>) => this.onEmailChange(e)}></input>
                    </p>
                    <p className="postit-inputs">
                        <label className="contact-label" htmlFor="name">Nom</label>
                        <input className="contact-input" name="name" type="text" id="name" value={this.state.name} placeholder="Votre nom" onChange={(e: ChangeEvent<HTMLInputElement>) => this.onInputChange(e)}></input>
                    </p>
                    <p className="postit-inputs">
                        <label className="contact-label" htmlFor="phone">Téléphone</label>
                        <input className="contact-input" name="phone" type="text" id="phone" value={this.state.phone} placeholder="Votre téléphone" onChange={(e: ChangeEvent<HTMLInputElement>) => this.onInputChange(e)}></input>
                    </p>
                    <p className="contact-txtarea">
                        <label className="contact-label" htmlFor="textarea">{this.state.error}</label>
                        <TextArea id="textarea" maxLength={500} value={this.state.message}
                            onMouseEnter={(e: MouseEvent) => {
                                this.handleMouseEnter();
                            }}
                            onChange={(e: any) => this.onTextAreaChange(e)} />
                    </p>
                    <p className="postit">
                        <button
                            disabled={this.state.processing || this.state.isDisable} className="form-btn"
                            type="submit"
                        >
                            <span className="postit">
                                {this.state.processing ? "..." : "Envoyer"}
                            </span>
                        </button>
                    </p>

                </form></>

        )
    }
}

export const Input = styled.input`
font-family: 'Montserrat', sans-serif;
  font-size: 0.5em;
  background-color: rgba(245,239,230);
  border-radius: 5px;
  border-style: none;
  padding: 2px;
  width: fit-content;
  min-width: 10em;
&:focus{   
    outline: none;
}

`
export const TextArea = styled.textarea`

height: 10rem;
width: 80%;
padding: 0.5em;
resize: none;
overflow-x: hidden; 
overflow-y: hidden;
margin: 0 auto;
align-self: center;
border-style: solid;
border-width: 1px;
border-color: #A1A27E;
z-index: 1000;
border-radius: 20px;   
font-family: 'Lato';
&:focus{   
    outline: none;
}

`


export default PostIt



