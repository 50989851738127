

import React, { useContext } from 'react';
import '../styles/PersCards.css';
import CirclePlay from './CirclePlay';
import Key from './Key';
import {TheContext} from '../context/MyContext';

interface IplayClick{
  onKeyClick: ()=>void;
}


export default function PlayKey(props: IplayClick) {
  const myContext = useContext(TheContext);

  const handleKeyClick=()=>{
    console.log("on passe dans handlebowlclick");
    // toggleplay house anim
    myContext.letsPlayPaperHouse ? myContext.setLetsPlayPaperHouse(false) : myContext.setLetsPlayPaperHouse(true);
}
  
  return (
  <button 
    className='play-group' 
    onClick={ (e: React.MouseEvent)=>{
      handleKeyClick();
  }}>
    <div className='play-wrapper'>
      <div 
      className='playcircle'  
     >
        <CirclePlay
          onKeyClick={props.onKeyClick}
          color="red"
          className='arrow-card'
          width={'50px'}
          height={'50px'}
        />
        
      </div>
      <div className='key-position'>
        <Key/>
      </div>
    </div>
  
 </button>
  )

}
