import { forwardRef } from 'react';
import { InfoBlock, InfoElement, InfoSectionWrapper } from '../common';
import photoAl from "../images/medaillon_Al.png";

interface InfoProps{
    id: number | null | undefined,
    details: string,
    description: string,
    title: string,
    date: string,
    level: string,
    sectionid: number
}

interface InfosProps {
    id: number | null | undefined,
    sectionid: number | null | undefined, 
    isActivBlock: boolean,
    infos: InfoProps[] ,
    sectionTitle: string,
    activSectionId: number | null,
    isLeft: boolean,
    images: string[]
    
}

export const InfosDetails = forwardRef < HTMLButtonElement | null, InfosProps >((props, ref) =>{
 

    return (
        <div className="info-grid">        
        <InfoBlock marginTop="0" blockWidth="90vw" isLeft={true} isActivBlock={props.isActivBlock}>
             <button className='invisiblebutton' id={props.id+'-targetBtn'} ref={ref}></button>             
              <div className="title-div">
              
            </div>   
            {/* <CompassBtn width="45px" height="45px" key="compass-bckward" className="compass-div" images={props.images} onClick={(e: any)=>console.log("forward")}/> */}
             
               <div className="div-for-grid"> 
               {props.id === 2 && <img className="photo-al" width="120px" height="120px" src={photoAl} alt="photo Aline" />}
               <h1 className="title-inline">{props.sectionTitle}</h1> 
               </div>
            {/* <CompassBtn  width="45px" height="45px" key="compass-forward" className="compass-div" images={props.images} onClick={(e: any)=>console.log("backward")}/> */}                  
            
            {props.infos && props.infos.map((info: InfoProps)=>            
                <InfoElement key={info.id} isActiv= {props.activSectionId === info.sectionid ? true : false}>
                    <div key={info.id+"infosection-header"} className="infosection-header">
                    <div key={info.id+"infosection-date"}>
                        <h2>{info.date && info.date}</h2>
                    </div>
                    
                    <InfoSectionWrapper key={info.id+"InfoSectionWrapper"} isLeft={props.isLeft && props.isLeft} className="infosection-wrapper"> 
                        <div key={info.id+"Infotitle"}>
                            <h2>{info.title}</h2>
                        </div>                        
                        <div key={info.id+"Infodesc"}><span className="xp-details">{info.description}</span></div>
                        <div key={info.id+"InfoLevel"}><span className="xp-details">{info.level}</span></div>
                        {info.details && <div key={info.id+"InfoDetails"}>{info.details}</div>}
                    </InfoSectionWrapper>
                    </div>
                    
                </InfoElement>
                
            )}
        
        </InfoBlock>
        
        </div>
    )
})
