import React, { forwardRef, ReactElement, Suspense, useEffect, useRef, useState, useContext } from 'react';
import "../styles/App.css";
import { BckgPosition, MovingTitle, PaperHouse, PhoneWrapper, SectionDiv } from '../common';
import { FixedDiv } from '../components/FixedDiv';
import {ProjectDetails} from '../components/ProjectDetails';
import PersCards from '../components/PersCards';
import { prestationDetails } from '../data/data';
import ScrollingCarousel from '../components/ScrollingCarousel';
import FallBackFrame from '../components/FallBackFrame';
import { TheContext } from '../context/MyContext';
import FallBackHousePaper from '../components/FallBackHousePaper';
import Services from './Services';


const LazyFrame = React.lazy(()=> import('../components/FrameHouse'));


interface SectionProps{
    id: number;
    title: string;
    component: ReactElement<any, any>;
    phoneNav: boolean;
    accessCtrl?:string;
    activSection: number;
    titleToAnim: Title | undefined;
    cardsVisible: boolean;
    blockIsScrollingX: boolean;
    topPosition: number;
    divHeight: number;
    paperHousePath: string[];
    /* letsAnimHouse: boolean; */
    firstPaperHouse: string;
    paperHouseIsLoaded: boolean;
    servicesAreVisible: boolean;
    servicesInfo: (Object | undefined)[]|undefined;
    savRef: (Object | undefined)[]|undefined;
    startingSav: (Object | undefined)[]|undefined;
    startingServices: (Object | undefined)[]|undefined;
}
interface Title{
    id: number;
    isVisible: boolean;
}

    export const Section = forwardRef < HTMLButtonElement | null, SectionProps >((props, ref) =>{
        
        const [ready, setReady] = useState<boolean>(false);
        const timerTitleRef = useRef<any>(null);
        const houseFocusRef = useRef<any>();

        const myContext = useContext(TheContext);
        useEffect(() => {
            if(myContext.letsPlayPaperHouse){
                if(houseFocusRef.current){
                    houseFocusRef.current.focus();
            }
            }        
            timerTitleRef.current = setTimeout(()=>{
                
                if (props.titleToAnim){
                    
                    if(props.activSection === props.id || props.titleToAnim.isVisible === true){
                        setReady(true);
                    }else{
                        setReady(false); // reinit
                    }
                }
            }, 10)
            
            return ()=>clearTimeout(timerTitleRef.current);
        })
    return (
       
        <>
        <div className="route-section">
        {/* disableTouche={myContext.pointerIsMoving} => disable scrolling when draps -- always false because now is click action instead of drag */}
        <SectionDiv disableTouch={false} sectionId={props.id} className="route-section"> 
        <PhoneWrapper id={"section-" + props.id } phoneNav={props.phoneNav} isHomeSection={props.phoneNav && props.id === 0}>    
            <button id={props.id.toString()+'-btn'} className="section-button"  value="" ref={ref}>                
                <h1 className="invisible-title">{props.title}</h1>
            </button>  
            {/* intro */}
            {props.id === 0 && <button ref={houseFocusRef} className="section-button"/>}
            <PaperHouse propStyled={myContext.letsPlayPaperHouse}>
                {props.id === 0 && 
                <Suspense fallback={<FallBackHousePaper frame={props.firstPaperHouse}/>}>
                    {props.paperHouseIsLoaded ? 
                    <LazyFrame id={0} frames={props.paperHousePath} letsAnim={myContext.letsPlayPaperHouse}/>
                    :
                    <FallBackHousePaper frame={props.firstPaperHouse}></FallBackHousePaper>}
                </Suspense>}
            </PaperHouse>
            <div className='title-position'>
            <MovingTitle sectionId={props.id} isVisible={ready} launchAnim={props.activSection === props.id || props.titleToAnim?.isVisible === true}>
                <span className="title1">{props.title}</span> 
            </MovingTitle>
            </div>
            {props.id !== 4 && props.component}
            {props.id === 2 && <ProjectDetails cardsVisible={props.cardsVisible} />}      
            {props.id === 2 && <PersCards/>}
            {props.id === 3 && <ScrollingCarousel blockIsScrollingX={props.blockIsScrollingX} blockData={prestationDetails}/>}
            {props.id === 4 && <Services startingSav={props.startingSav} startingServices={props.startingServices} savRef={props.savRef} servicesInfo={props.servicesInfo} servicesAreVisible={props.servicesAreVisible}/>}
        </PhoneWrapper>
        </SectionDiv>
        </div>
        {/* fond fixe */}
        <BckgPosition className="bckg-position" topPosition={props.topPosition} divHeight={props.divHeight} sectionId={props.id}/>
        <FixedDiv sectionId={props.id}/>
        </>
    )});
    
