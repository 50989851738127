import {sites} from "../data/data";

interface Isite {
    id: number,
    img: string, 
    alt: string, 
    siteDetails: string, 
    link: string, 
    job : string, 
    details: string
}
export const SiteCards = () =>{
    return(
        <div className="retro-wrapper">
        <div className='retro-container'>
        <div className='border-retro-title'></div>
        <h2 className='retro-title'>
        Sites et applications web
        </h2>
        <div className='border-retro-title'></div>
        </div>
        {sites.map((aSite: Isite)=>(
            <div key={aSite.id} className="title-integration">                
            <div className="title-title-integration">
            <div>
            <h2>Zoom</h2>
            <span>{aSite.siteDetails}</span>
            <h2>Outils</h2>
            <p>{aSite.details}</p>
            <h2>Réalisation</h2>
            <p>{aSite.job}</p>
            {aSite.id <5 ?
                <a href={aSite.link} target="_blank" className='site-btn'>Accéder au site</a>
                : <p>{aSite.link}</p>}
                </div>
                </div>
                <div className="img-clip-left">
                
                {aSite.img && <img src={aSite.img} alt={aSite.alt} className="img-site">
                </img>}
                </div>
                
                </div>
                )
                
                )}
                </div>
                
                )
            }