
import { ArrowPosition } from '../common';
import ArrowPointer from './ArrowPointer';

export const Price=({...props})=> {
    const animatedArrowProps = {
        color:"#212E53", 
        className: props.arrowVisibility ? (props.pointerDown ? 'arrowpop' : 'arrowwait') : 'invisible-div', 
        isLeft: false, 
        width:'50px', 
        height: '50px'};
  return (
   
        <ArrowPosition height={props.height}>
            <ArrowPointer {...animatedArrowProps}/>
        </ArrowPosition>
   
  )
}

