import React, { Component, MutableRefObject } from 'react';
import '../styles/App.css';
import diplomasFallBack from '../images/about/diplomas.png';
import experienceFallBack from '../images/about/experience.png';
import skillsFallBack from '../images/about/skills.png';
import infoFallBack from '../images/about/infos.png';
import { Suspense } from 'react';
import FallBackBtns from './FallBackBtns';
const LazyBtns = React.lazy(() => import('./LazyBtns'));


const infoImports = require.context("../../public/Anims/Info/", true, /\.png|jpe?g$/);
const skillsImports = require.context("../../public/Anims/Skills/", true, /\.png|jpe?g$/);
const diplomasImports = require.context("../../public/Anims/Diplomas/", true, /\.png|jpe?g$/);
const experienceImports = require.context("../../public/Anims/Experience/", true, /\.png|jpe?g$/);


const InfoPath= infoImports.keys().map(myImport=> myImport.replace("./", "Anims/Info/"));
const SkillsPath= skillsImports.keys().map(myImport=> myImport.replace("./", "Anims/Skills/"));
const DiplomasPath= diplomasImports.keys().map(myImport=> myImport.replace("./", "Anims/Diplomas/"));
const ExperiencePath= experienceImports.keys().map(myImport=> myImport.replace("./", "Anims/Experience/"));

interface SuperProps{
    onClick(btnId: number): void,
    buttonsId: string
}


export default class AnimatedButtons extends Component<SuperProps> {
    timerPlay: React.MutableRefObject<any|null|undefined>;
    timerRewind: React.MutableRefObject<any|null|undefined>;

    constructor(props: SuperProps){
        super(props)
        this.timerPlay = React.createRef<MutableRefObject<any>>();
        this.timerRewind = React.createRef<MutableRefObject<any>>();
    }

    btnLst = [
        {id: 0, title: "Diplômes" , imgLst: DiplomasPath},
        {id: 1, title: "Expérience" , imgLst: SkillsPath},
        {id: 2, title: "Infos" , imgLst: InfoPath},
        {id: 3, title: "Compétences" , imgLst: ExperiencePath}

    ]
    fallBackBtnLst = [
        {id: 0, title: "Diplômes" , imgLst: [diplomasFallBack]},
        {id: 1, title: "Expérience" , imgLst: [skillsFallBack]},
        {id: 2, title: "Infos" , imgLst: [infoFallBack]},
        {id: 3, title: "Compétences" , imgLst: [experienceFallBack]}
    ]
    state = {
        sameId: null,
        clickedId: -1,
        oldId: -1,
        animatedBtn: -1,       
        activIds: [{btnId: 0, activId: 0},{btnId: 1, activId: 0},{btnId: 2, activId: 0},{btnId: 3, activId: 0}],
        openStates: [{id: 0, isOpen: false},{id: 1, isOpen: false},{id: 2, isOpen: false},{id: 3, isOpen: false}],
        clickedBtn: -1,
        btnList: this.btnLst,
        btnClicks: [{btnId: 0, time: 0, isClosed: true}, {btnId: 1, time: 0, isClosed: true}, {btnId: 2, time: 0, isClosed: true}, {btnId: 3, time: 0, isClosed: true}],
        hoveredId : null
    }

  
    formatRewindData(pImgTable: string[]){
       
        let reverseImages;
        reverseImages = pImgTable.map((item, key)=>{                    
            // créé l'objet image à partir de l'item, 
            // y ajoute l'id créé par la key
            let image;
            image = item
            // pour chaque item on retourne une image
            return image; // push
        })
        // on reverse le tableau
        reverseImages.reverse();
        // on retourne la liste d'images ou la liste inversée     
        return reverseImages;
    }

    toggleButtonRole = (btnId: number) =>{
        this.props.onClick(btnId);
        const times = this.updateBtnTimes(btnId);
        this.setState(
            (prevState:any) => {
              return {                
                oldId: prevState.clickedId,
                clickedId: btnId,
                sameId: prevState.clickedId === btnId ? btnId : null
              };
            },
            () => {                                      
                    this.setState({btnClicks: times});
                    this.playAnims();
                }
          );
        
    }
    
 updateBtnLst=(pBntId: number, pImgLst: string[])=>{    
    let newList
    newList = this.state.btnList.map((item, key)=>{                    
        // met à jour la liste à son emplacement 
        if (item.id === pBntId){                           
            item = {id: item.id, title: item.title, imgLst: pImgLst}
        }else{
            item = {id: item.id, title: item.title, imgLst: item.imgLst}
        }
        // pour chaque item on retourne une image ou une liste d'images
        return item; // push
    
    });        
    // on retourne la liste d'images
    return newList;
 }

 updateActivIds=(pBntId: number, i: number)=>{    
    let newList
    newList = this.state.activIds.map((activBtn, key)=>{                    
        // met à jour l'id actif à son emplacement 
        if (activBtn.btnId === pBntId){            
            activBtn = {...activBtn, activId: i}
        }else{           
            activBtn = {...activBtn}
        }
        // pour chaque item on retourne une image
        return activBtn; // push
    
    });   
    return newList;
 }

 clearBtnTimes=(pBntId: number)=>{    
    let newList
    newList = this.state.btnClicks.map((btnClick)=>{                    
        // ajoute un au nombre de clics du bouton en param sinon reset 
        if (btnClick.btnId === pBntId){            
            btnClick = {...btnClick, time: 0}
        }else{            
            btnClick = {...btnClick} // si pas cliqué
        }        
        return btnClick; // push
    
    });   
    return newList;
 }
 updateBtnTimes=(pBntId: number)=>{    
    let newList
    newList = this.state.btnClicks.map((btnClick)=>{                    
        // ajoute un au nombre de clics du bouton en param sinon reset 
        if (btnClick.btnId === pBntId){            
            btnClick = {...btnClick, time: this.state.btnClicks[pBntId].time + 1}
        }else{            
            btnClick = {...btnClick} // si pas cliqué, reset
        }        
        return btnClick; // push
    
    });   
    return newList;
 }
 updateBtnIsOpen=(pBntId: number, pIsOpen: boolean)=>{    
    let newList
    newList = this.state.openStates.map((btnClick)=>{                    
        // ajoute un au nombre de clics du bouton en param sinon reset 
        if (btnClick.id === pBntId){            
            btnClick = {...btnClick, isOpen: pIsOpen}
        }else{            
            btnClick = {...btnClick} 
        }        
        return btnClick; // push
    
    });   
    return newList;
 }

letsPlay = (pBtnId: number, pImgLst: string[])=>{
   
    const start = 0; 
    const end = pImgLst.length-1;
    for (let i = start; i <= end;  i++){                    
        this.timerPlay.current = setTimeout(
            () =>{                    
                const currentActivIds = this.updateActivIds(pBtnId, i);
                this.setState({activIds: currentActivIds});
                if ( i >= end){ // anim stop                                                       
                    clearTimeout(this.timerPlay.current);  
                    // on tourne la liste pour préparer la lecture rewind seulement
                    // si l'id du bouton correspond à l'id cliqué
                    const currentList = this.formatRewindData(pImgLst);                       
                    const updatedLst = this.updateBtnLst(pBtnId, currentList);
                    this.setState({btnList: updatedLst}, ()=>{                    
                        this.setState({activIds: this.updateActivIds(pBtnId, 0)});
                        this.setState({openStates : this.updateBtnIsOpen(pBtnId, true)});
                    });                         
                } 
            }                 
        , i * 30);
        
        }  
       
}

    formatPlayData(pImgList:string[]){
        let images;
        images = pImgList.map((item, key)=>{                    
            // créé l'objet image à partir de l'item, 
            // y ajoute l'id créé par la key
            let image;
            image = item
            // pour chaque item on retourne une image
            return image; // push
        });        
        // on retourne la liste d'images  
        return images;
    }

    letsRewind = (pBtnId: number, pImgLst: string[])=>{
        const start = 0;            
        const end = pImgLst.length-1;
        for (let i = start; i <= end;  i++){                                       
            this.timerRewind.current = setTimeout(
                () =>{
                    const currentActivIds = this.updateActivIds(pBtnId, i);
                    this.setState({activIds: currentActivIds});
                    if ( i >= end){ // anim stop                                                     
                       clearTimeout(this.timerRewind.current);
                       // on remet le tableau d'images dans le bon sens
                        const currentList = this.formatRewindData(pImgLst);
                        const updatedLst = this.updateBtnLst(pBtnId, currentList);
                        this.setState({btnList: updatedLst}, ()=>{                           
                           this.setState({activIds: this.updateActivIds(pBtnId, 0)});
                           this.setState({openStates : this.updateBtnIsOpen(pBtnId, false)});                    
                        });  
                       
                   }
               }                 
            , i * 30);
            
        } 
       
    }
    playAnims = () =>{
        const {btnList} = this.state;
        // parcourt la liste des boutons et joue les anims de chaque bloc
        btnList.map((btn)=>{this.playAnim(btn.id, btn.imgLst)})
        
    }
    playAnim =  (pBtnId: number, currentImgList: string[])=>{
        const {sameId, clickedId, oldId, openStates} = this.state;
     
        switch (pBtnId) {
            case sameId:
                /* if (btnClicks[pBtnId].time % 2 === 0){ */
                    if (openStates[pBtnId].isOpen === false){ 
                    this.letsPlay(pBtnId, currentImgList);                            
                    
                }else{
                    this.letsRewind(pBtnId, currentImgList);                   
                    
                }
                // toggle
                break;

            case clickedId:  
                
                this.letsPlay(pBtnId, currentImgList);
                
            break;

            case oldId: 
            if (openStates[pBtnId].isOpen === true){               
                    this.letsRewind(pBtnId, currentImgList);
                    this.setState({btnClicks: this.clearBtnTimes(pBtnId)});
                   
                }else{
                    this.setState({btnClicks: this.clearBtnTimes(pBtnId)});
                    const updatedActivIds = this.updateActivIds(pBtnId, 0);
                    this.setState({activIds: updatedActivIds});
                }
                break;
        
            default:            
                const updatedActivIds = this.updateActivIds(pBtnId, 0);
                this.setState({activIds: updatedActivIds});                
                break;
        }
        
 
    }
    
    toggleHover = (pSectionId: number | null | undefined): number | null |undefined =>{
        return pSectionId === this.state.hoveredId ? null : pSectionId;
    }
    handleHover =(e: React.MouseEvent, id: number)=>{ 
        const hoverToggle = this.toggleHover(id);
        this.setState({hoveredId: hoverToggle});
           
    }
    handleLeave =(e: React.MouseEvent, id: number)=>{ 
        this.setState({hoveredId: null});           
    }
    


    render() {

        return (
            
            <Suspense fallback={           
            <><FallBackBtns btnList={this.fallBackBtnLst} onClick={this.props.onClick} /></>
            }>
            <LazyBtns
            id={this.props.buttonsId}
            btnList = {this.state.btnList}           
            handleHover = {this.handleHover}
            handleLeave = {this.handleLeave}
            hoveredId = {this.state.hoveredId}
            clickedId = {this.state.clickedId}
            activIds = {this.state.activIds}
            oldId={this.state.oldId}
            sameId={this.state.sameId}            
            onClick={                
                this.toggleButtonRole
            }
            />
            </Suspense>
            
        )
    }
}
