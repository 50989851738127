import React, { useContext } from 'react'
import { MovingLoading } from '../common'
import { TheContext } from '../context/MyContext';
import "../styles/App.css";

export default function FallBackFrame(props:{frame: string}) {
 const myContext = useContext(TheContext);
 
  
  return (
  <div className='frames'>    
        <div className='frame-position'>          
          <img src={props.frame} alt="...chargement en cours"/> 
        </div>
  </div>
   
  )
}
