import styled, {keyframes} from 'styled-components';

interface AppProps{
    disableTouch: boolean;
}
interface ArrowPositionProps{
    height: number;
}

interface DrapsContainerProps{
    height: number;
    percentPosition: number;

}
interface RowOpacityprops{
    isVisible: boolean;
}
interface CurvedLetterProps{
    angle : number, 
    translateX: number, 
    translateY: number
}
interface CurvedLoadingProps{
    angle : number, 
    translateX: number, 
    translateY: number,
    position: number
}

interface PriorBlock{
    priorityId: number;
    dataId: number;
    numberOfItems: number;
}
interface NavLiProps{
    isActivSection: boolean;
}
interface SectionProps{
    sectionId: number;
    divHeight: number;
    topPosition: number;
    disableTouch: boolean;
}

interface StyledAppearingCard{
    canAppear: boolean;
}
/**
 * CAROUSEL
 */
interface StyledPropsCarousel{
    isActiv: boolean;
    isPrevious: boolean;    
}
interface MovingTitleProps{
    launchAnim: boolean;
    isVisible: boolean;
    sectionId: number;
}
interface MovingLoading{
    isLoading: boolean;
    id: number;
}
interface StyledPropsCompass{
    isActiv: boolean;
    width: string;
    height: string;
}

interface StyledPropsInfoDiv{
    isActiv: boolean;
}
interface StyledPropsAnimImgs{
    isActiv: boolean;     
}

interface StyledPropsInfoBlock{
    isActivBlock: boolean;
    isLeft: boolean | undefined;
    blockWidth: string;
    marginTop: string;
   
}
interface StyledPropsContactBlock{
    isActivBlock: boolean;  
    blockWidth: string;
    marginTop: string;
}

interface StyledPropsInfoSectionWrapper{    
    isLeft: boolean | undefined;
}
interface SwipeElt{
    bckgColor: string;
}
interface SwipeDiv{
    isMovingRight: boolean | undefined;
    isMovingLeft: boolean | undefined;
}

interface StyledProps{
  propStyled: boolean;
  
}
interface StyledPropsNav{
  phoneNav: boolean;
  
}

interface StyledPropsSection{
    phoneNav: boolean;
    isHomeSection: boolean;
  }
interface StyledImgSection{
    isHover: boolean;
    isPlaying: boolean;
}

interface InfoStyledBtn{
    isToDisable: boolean;
}

interface AnimCardProps{
    letsAnimCards: boolean;
  
}
interface UpAndDownProps{
    isActiv: boolean;
    isOld: boolean;
    isSame: boolean;
}
interface AutoSlideProps{
    activId: number;
}
export const SwipeButton = styled.button <SwipeElt>`
    touch-action: manipulation;
    background-color: ${(props:SwipeElt)=> props.bckgColor};
    width: 10px;
    height: 10px;
    border-radius: 50%;


`

export const SwipeDiv = styled.div <SwipeDiv>`
    touch-action: manipulation;
    position: absolute;
    width: 500px;
    height: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    transform: ${(props: SwipeDiv)=> props.isMovingRight ? "translateX(100%);" : props.isMovingLeft ? "translateX(0%);" : 'none'};

`
export const InfoButton = styled.button <InfoStyledBtn>`
    position: relative;
    display: flex;
    border-style: none; 
    cursor: pointer;
    width: 20em;
    height: auto;
    &:disabled{
       cursor: none;
      }
    @media screen and (max-width: 992px){
        width: 8em;
        height: 8em;
    }
`

export const TitleAnim = keyframes `
  
    0% {
      transform: skewX(0deg);
      transform: scaleX(0%);
      transform: translateX(-100%);
   
    }
    25%{
      transform: skewX(0deg);
      transform: scaleX(0%);
      
    }
  
    50%{    
      transform: skewX(50deg);
      transform: scaleX(120%);
     
    }
   
    75% {
      transform: skewX(0deg);
      transform: scaleX(100%);
      transform: translateX(0%);
    }
    100% {  
      transform: scaleX(100%);
      transform: translateX(0%);
    }
`
export const MovingTitle = styled.div<MovingTitleProps>`
    width: inherit;
    height: inherit;    
    display: block;
    position: absolute;
    top: 0;
    left: ${(props: MovingTitleProps)=>props.launchAnim ? '0':'-500em'};
    opacity: ${(props: MovingTitleProps)=>props.isVisible ? '100%':'0%'};
    transform-origin: left;
    -webkit-animation-name: ${(props: MovingTitleProps)=>props.launchAnim ? 'slideIn, scaling, skewX':'none'};
    -webkit-animation-delay: 0.1s;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation: left, opacity, transform-origin;
    animation-delay: 0.1s;
    animation-duration: 2s;
    animation-name: ${(props: MovingTitleProps)=>props.launchAnim ? 'slideIn, scaling, skewX':'none'};
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    max-width: 100vw;
    width: 100%;   
`
const scaling = keyframes `
    0% { 
        transform: scaleX(0); 
        webkit-transform: scaleX(0);
    }
    25%   { 
        transform: scaleX(0); 
        webkit-transform: scaleX(0);
    }
    50%   { 
        transform: scaleX(1); 
        webkit-transform: scaleX(1);
    }
    100%   { 
        transform: scaleX(0.75); 
        webkit-transform: scaleX(0.75);
    }
`
export const slideOut = keyframes `
0% {      
  transform: translateX(0%);
}   
100% {       
    transform: translateX(100%);
}

`
export const MovingLoading = styled.div<MovingLoading>`
    animation: .5s linear 1s infinite alternate ${slideOut}; 
    text-align: left;
    color: #ED4353;
    font-size: 4em;
    padding: 0.1em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    :nth-child(2n){
        animation: .5s linear .5s infinite alternate ${slideOut}; 
    }
`


export const goDown = keyframes `
    0% {      
      top:0;
    }   
    100% {       
       top:15em;
    }   
    `
    export const goUp = keyframes `
    0% {      
      top:15em;
    }   
    100% {       
        top:0;
    }   
    `
   

    export const ButtonPosition = styled.button<UpAndDownProps>`
    animation: 1s ${(props: UpAndDownProps)=>props.isSame === true ? goUp : props.isActiv === true ? goDown : props.isOld === true ? goUp : "none;" }; 
    z-index: ${(props:UpAndDownProps)=>props.isActiv === true ? '10000' : '2000'};
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
    position: relative;
    top: ${(props: UpAndDownProps)=>props.isSame === true ? '0' : props.isActiv === true ? '15em' : props.isOld === true ? '0' : "none;" }; 
    width: 100%;
    height: 100%;
    border-style: solid;
    cursor: pointer;
    clip-path: circle(50%);
    border-radius: 50%;
    border-style: solid;
    border-color: rgba(237, 67, 83, 0.8);
    /* background-color: rgba(245,239,230); */
    filter: brightness(1);    
  
    
`
export const ButtonAnimation = styled.div<UpAndDownProps>`

position: relative;
width: 90%;
height: 90%;

cursor: pointer;

/* background-color: rgba(245,239,230); */
filter: brightness(1);
margin: 0 auto;


`
export const CurvedLetter = styled.div<CurvedLetterProps>`
    left: 50%;
    top: 50%;
    position: absolute;
    display: inline-block;
    transform-origin: 0;
    transform:     
        translate(${(props: CurvedLetterProps)=>props.translateX}px, ${(props: CurvedLetterProps)=>props.translateY}px) 
        rotate(${(props: CurvedLetterProps)=>props.angle}deg);
        
   

`
export const CurvedLoading = styled.div<CurvedLoadingProps>`
    left: 50%;
    top: 50%;
    position: absolute;
    display: inline-block;
    transform-origin: 0;
    color: #1a2f42;
    transform:   
        translate(${(props: CurvedLoadingProps)=>props.translateX}px, ${(props: CurvedLetterProps)=>props.translateY}px) 
        rotate(${(props: CurvedLoadingProps)=>props.angle}deg)
        scale(${(props: CurvedLoadingProps)=>props.position})
        ;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        
   :nth-child(2n){
     
   }

`
export const _CurvedLetter = styled.div<CurvedLetterProps>`    
    top: -100px;
    position: absolute;
    display: inline-block;
    transform-origin: 0 100px;
    transform: rotate(${(props: CurvedLetterProps)=>props.angle}deg);

`

export const ImgSection = styled.img<StyledImgSection>`
    filter: saturation(${(props: StyledImgSection)=>props.isHover || props.isPlaying ? '3' : '1'});
    filter: brightness(${(props: StyledImgSection)=>props.isHover || props.isPlaying ? '1.25' : '1'});
    filter: grayscale(${(props: StyledImgSection)=>props.isHover || props.isPlaying ? '0%' : '100%'});
    transform: scale(${(props: StyledImgSection)=>props.isHover || props.isPlaying ? '1.25' : '1'});
    transition: transform 0.5s ease-out;
    transition-property: tranform, filter;
    transition-timing-function: ease-in;
    transition-duration: 1s;
    
    
    
`
export const H1Section = styled.span<StyledImgSection>`    
    opacity: ${(props: StyledImgSection)=>props.isHover ? '0' : '1'};
    font-size: 2em;
    font-weight: 700;
    font-family: 'Julius Sans One', sans-serif;
    color:#A1A27E;
    padding: 1em;
    padding-bottom: 2em;
    @media screen and (max-width: 992px){
        font-size: 1em;
    }
`
export const FixedBackgroundDiv = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url("../images/background.jpg");
  background-blend-mode:luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  opacity: 1;
  margin: 0 auto;
  background-color:rgba(245,239,230);
  /* background-color: #EED7C5; */
 background-attachment: fixed;
`

export const skewX = keyframes `
    0% {      
      transform: skewX(-100%);
    }   
    100% {       
      transform: skewX(0%);
    }   
    `
    export const slideIn = keyframes `
    0% {      
      transform: translateX(-100%);
    }   
    100% {       
        transform: translateX(0%);
    }   
    `

    export const popUp = keyframes `
    
    0% {      
      transform: translateY(100%);
    
    }   
    100% {       
        transform: translateY(0%);
    }
   
    `

    export const birth = keyframes `
    0% {      
      transform: scaleX(0%);
      opacity: 0;
    }   
    100% {       
        transform: scaleX(100%);
        opacity: 1;
    }
   
    `

  
   export const fadeIn = keyframes `
    0% {      
      opacity: 0%;
    }
    
   
    100% {       
        opacity: 100%;
    }    
    `
    export const fadeOut = keyframes `
    0% {      
      opacity: 100%;
    }
    
   
    100% {       
        opacity: 0%;
    }    
    `
    export const zoomIn = keyframes `
    0% {      
      opacity: 0%;
      transform: scale(1);
    }
    50%{
        opacity: 100%;
       
    }
   
   
    100% {       
        transform: scale(1.25);
    }    
    `
export const AnimBottle = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${(props: StyledPropsAnimImgs)=>props.isActiv ? '1' :  '0'};
  
`
export const _CarouselDiv = styled.button<StyledPropsCarousel>`
  animation: 1s ${(props: StyledPropsCarousel)=>props.isActiv && slideIn }; 
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsCarousel)=>props.isActiv ? '1' :  '0'};
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-style: none;
  
`
export const CarouselDiv = styled.button<StyledPropsCarousel>`
  animation: 5s ${(props: StyledPropsCarousel)=>props.isActiv && zoomIn }; 
  top: 0;
  left:0;
  margin: 0 auto;
  position: absolute;
  opacity: ${(props: StyledPropsCarousel)=>props.isActiv ? '1' :  '0'};
  width: 100%;
  height: 100%;
  border-style: none;
  transition: opacity 1s linear, animation 1s linear;
  
`

export const CompassDiv = styled.div<StyledPropsCompass>`

  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsCompass)=>props.isActiv ? '1' :  '0'};
  width: ${(props: StyledPropsCompass)=>props.width};
  height: ${(props: StyledPropsCompass)=>props.height};
  max-height: 100%;
  
`
export const RowOpacity = styled.div <RowOpacityprops>`
opacity: ${(props: RowOpacityprops)=>props.isVisible ? '1' : '0'};

`
export const DrapsWrapper = styled.div<DrapsContainerProps>`

    position: relative;
    width: inherit;
    height: ${(props: DrapsContainerProps )=> props.height}; 
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;

`
export const DrapsContainer = styled.div<DrapsContainerProps>`
    height: fit-content;
    margin-bottom: 1em;
    text-align: -webkit-center;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    display: block;   
    z-index: 5000;
    cursor: grabbing;
    :before {
        content : "";
        position: absolute;
        left    : 25%;
        bottom  : 0;
        height  : 40px;
        width   : 50%;
        border-top: 2px solid #1a2f42;
      }
`
export const _DrapsContainer = styled.div<DrapsContainerProps>`
    /* height: ${(props: DrapsContainerProps)=>props.height && props.height+'px' }; */
    height: fit-content;
    margin-bottom: 1em;
    text-align: -webkit-center;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    display :block;   
    z-index: 5000;
    cursor: grabbing;
    :before {
        content : "";
        position: absolute;
        left    : 25%;
        bottom  : 0;
        height  : 1px;
        width   : 50%;
        border-bottom: 2px solid #1a2f42;
      }
`
export const ArrowPosition = styled.div`
height: fit-content;
transform: rotate(-90deg);
position: relative;
display: block;
width: fit-content;

`
export const _ArrowPosition = styled.div<ArrowPositionProps>`
/* bottom: -${(props: ArrowPositionProps)=>props.height}px; */
height: fit-content;
transform: rotate(-90deg);
position: relative;
display: block;
width: fit-content;

`

export const InfoDiv = styled.div<StyledPropsInfoDiv>`

  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsInfoDiv)=>props.isActiv ? '1' :  '0'};
  width: inherit;
  height: inherit;
  max-height: 100%;
  
`
export const AnimatedImgsDiv = styled.div<StyledPropsAnimImgs>`

  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsAnimImgs)=>props.isActiv ? '1' :  '0'};
  width: 150px;
  height: 150px;
  max-height: 100%;
  
`
export const AnimCard = styled.div<AnimCardProps>`
  animation: 1s ${(props: AnimCardProps)=>props.letsAnimCards === true ? popUp : "none;" }; 
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  /* transition-delay: 0.50s;   */
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
`

export const BlockContent = styled.div<StyledAppearingCard>`
 
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100000;
    background-color: rgba(237, 67, 83, 0.8);
   /*  background-color: rgba(12,12,12,0.8); */
    color:rgba(245, 235, 237, 1);
    height: 5em;
    width: inherit;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 1% 1% 10px 10px;

  animation: 1s ${(props: StyledAppearingCard)=>props.canAppear === true ? birth : "none;" }; 
  transform-origin: left;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
 
  margin: 0 auto;
  animation-duration: 1s;
  animation-iteration-count: 1;
 
  opacity: ${(props: StyledAppearingCard)=>props.canAppear === true ? "1" : "0;" }; 

@media screen and (max-width: 992px){
    height: 8em;
}
`

export const PriorityBlock = styled.div<PriorBlock>`
text-align: center;
position: absolute;
 width: 80%;
 height: 80%;
 max-width: 100vw;
 margin: 0 auto;
 /* background-color: #FAF2EA; */
 background-color: #FAF2EA; 
 transform-style: preserve-3d;
 transform: scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*2}) translateX(${(props: PriorBlock)=>props.priorityId*2}%) rotateX(${(props: PriorBlock)=>props.priorityId*5}deg);
 -webkit-transform: scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*2}) translateX(${(props: PriorBlock)=>props.priorityId*2}%) rotateX(${(props: PriorBlock)=>props.priorityId*5}deg);
 perspective-origin: center center;
 transition: transform, left, z-index 0.8s, 0.8s, 0.1s ease-in; 
 -webkit-transition: transform, left, z-index 0.8s, 0.8s, 0.1s ease-in; 
 z-index : ${(props: PriorBlock)=>props.priorityId*(-100)}; 
 border-radius: 10px;
 display: block;
 
 :nth-child(2n){    
    background-color: #EED7C5;   
  
 }
 /*IPHONE 4*/
 @media only screen 
    and (device-width : 375px) 
    and (device-height : 667px) 
    and (-webkit-device-pixel-ratio : 2) { 
      -webkit-transform: translateZ(${(props:PriorBlock)=>props.priorityId*(-1)}px) scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%) ; 
    }
 @media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 
  -webkit-transform: translateZ(${(props:PriorBlock)=>props.priorityId*(-1)}px) scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%); 
 }
 @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5){
  -webkit-transform: translateZ(${(props:PriorBlock)=>props.priorityId*(-1)}px) scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%); 
}
@media screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2){
  -webkit-transform: translateZ(${(props:PriorBlock)=>props.priorityId*(-1)}px) scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%); 
}
`
/*bckup*/
export const ScalePriorityBlock = styled.div<PriorBlock>`
top: 5em; 
position: absolute;
 width: 60%;
 height: fit-content;
 max-width: 100vw;
 border: solid 2px #CE8F8A;
 margin: 0 auto;
 background-color: white;
 left: ${(props: PriorBlock)=>props.priorityId===0 ? '20%':props.priorityId*props.numberOfItems+'%'};
 transform-style: preserve-3d;
 transform: translateX(${(props: PriorBlock)=>props.priorityId*5}%) scale(${(props: PriorBlock)=>(
     props.priorityId === 0 ? '1.25' :
     props.priorityId === 1 ? '0.99':
     props.priorityId === 2 ? '0.98':
     props.priorityId === 3 ? '0.97':
    
    '0'
     
     )});
 perspective-origin: center center;
 transition: transform,left 0.8s ease-in; 
 z-index : ${(props: PriorBlock)=>props.priorityId*-100}; 
 border-radius: 10px;
 :nth-child(2n){
    border-color: #ED4353;
 }
 
`


export const DomainsDiv = styled.div<StyledPropsCarousel>`
  animation: 1s ${(props: StyledPropsCarousel)=>props.isActiv ? fadeIn : props.isPrevious ? fadeOut : "none;" }; 
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  transition-delay: 0.25s;
  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsCarousel)=>props.isActiv ? '1' :  '0'};
  width: inherit;
  top: 0;
  left:0;
  height: fit-content;
  max-height: 100%;  
  font-size: 1.5em;

`
export const InfoSectionWrapper = styled.div<StyledPropsInfoSectionWrapper>`
text-align: ${(props: StyledPropsInfoSectionWrapper)=>props.isLeft === true ? 'left;' :'right;'}  ;
@media screen and (max-width: 992px){
    font-size: 0.8em;
}
`


export const InfoBlock = styled.div<StyledPropsInfoBlock>`
    width: ${(props: StyledPropsInfoBlock)=>props.blockWidth};    
    height: ${(props: StyledPropsInfoBlock)=>props.isActivBlock === true ? 'auto' :  '0vh'};
    overflow-y: scroll; 
    overflow-x: hidden;    
    text-align: center ;    
    position: relative;
    transform-origin: top left;
    z-index: 1000;
    border-radius: 20px;   
    border-style: solid;
    border-width: 1px;    
    box-shadow: 0 2px 4px rgba(200, 200, 200, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
    opacity: ${(props: StyledPropsInfoBlock)=>props.isActivBlock === true ? '1' :  '0'};      
    animation: 1.5s ${(props: StyledPropsInfoBlock)=>props.isActivBlock && openDiv }; 
        
    /*SCROLLBAR*/
    ::-webkit-scrollbar {
    width: 0px; /* width of the entire scrollbar */
    
    }
    ::-webkit-scrollbar-track {
       
        background: #6A645A;        /* color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {    
        /* color of the scroll thumb */
        background-image: -webkit-linear-gradient(top,
                            #2f2c09 0%,                         
                            #bba803 100%);
        /* border-radius: 20px; */       /* roundness of the scroll thumb */
        border: 3px solid #6A645A;  /* creates padding around scroll thumb */
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
        
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background-image: -webkit-linear-gradient(top,
                            #bba803  0%,                         
                            #f8fc2d 100%); 
    }
    /*Firefox*/

    scrollbar-width: none; /* "auto" or "thin" */
    scrollbar-color: #2f2c09 #bba803;   /* scroll thumb and track */


    
    @media screen and (max-width: 992px){ 
        position: relative;    
        width: 90%;
        z-index: 1000;
        
    }
`
export const InfoBlockTest = styled.div<StyledPropsInfoBlock>`
    width: ${(props: StyledPropsInfoBlock)=>props.blockWidth};
    height: 20em;
    overflow-y: scroll; 
    overflow-x: scroll;    
    text-align: center ;
    margin: 1em;
    position: absolute;
    transform-origin: top left;   
    left: ${(props: StyledPropsInfoBlock)=>props.isLeft === true ? '0' :'auto'};
    right: ${(props: StyledPropsInfoBlock)=>props.isLeft === false ? '0' : 'auto'}; 
    z-index: 9999;
    background-color: rgba(106, 100, 90, 1);
    border-style: solid;
    border-width: 1px;
    border-color: #A1A27E;
    box-shadow: 0 2px 4px rgba(200, 200, 200, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
    opacity: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? '1' :  '0'};      
    animation: 1s ${(props: StyledPropsInfoBlock)=>props.isActivBlock && openDiv }; 
        
  
    
    @media screen and (max-width: 992px){
       
        height: 15em;        
        width: ${(props: StyledPropsInfoBlock)=>props.blockWidth};
        z-index: 9999;
        
    }
`
export const ContactBlock = styled.div<StyledPropsInfoBlock>`
    width: ${(props: StyledPropsInfoBlock)=>props.blockWidth};    
    height: ${(props: StyledPropsInfoBlock)=>props.isActivBlock === true ? '100vh' :  '0vh'};
    margin: 0 auto;
    background-color: white;
    overflow-y: scroll; 
    overflow-x: hidden;    
    text-align: center ;    
    position: relative;
    transform-origin: top left;
    z-index: 1000;
    border-radius: 20px;   
    border-style: solid;
    border-width: 1px;
    border-color: #A1A27E;
    box-shadow: 0 2px 4px rgba(200, 200, 200, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
    opacity: ${(props: StyledPropsInfoBlock)=>props.isActivBlock === true ? '1' :  '0'};      
    animation: 1.5s ${(props: StyledPropsInfoBlock)=>props.isActivBlock && openDiv }; 
        
    /*SCROLLBAR*/
    ::-webkit-scrollbar {
    width: 0px; /* width of the entire scrollbar */
    
    }
    ::-webkit-scrollbar-track {
       
        background: #6A645A;        /* color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {    
        /* color of the scroll thumb */
        background-image: -webkit-linear-gradient(top,
                            #2f2c09 0%,                         
                            #bba803 100%);
        /* border-radius: 20px; */       /* roundness of the scroll thumb */
        border: 3px solid #6A645A;  /* creates padding around scroll thumb */
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
        
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background-image: -webkit-linear-gradient(top,
                            #bba803  0%,                         
                            #f8fc2d 100%); 
    }
    /*Firefox*/

    scrollbar-width: none; /* "auto" or "thin" */
    scrollbar-color:  #2f2c09 #bba803;   /* scroll thumb and track */


    
    @media screen and (max-width: 992px){ 
        position: relative;    
        width: 100%;
        z-index: 1000;
        
    }
`
export const _ContactBlock = styled.div<StyledPropsContactBlock>`
    width: ${(props: StyledPropsContactBlock)=>props.blockWidth};
    height: fit-content;
    overflow-y: hidden; 
    overflow-x: hidden;    
    text-align: center ;
    margin: 0 auto;
    position: relative;
    transform-origin: top left;  
    background-color: rgba(106, 100, 90, 0.8);
    border-style: solid;
    border-width: 1px;
    border-color: #A1A27E;
    box-shadow: 0 2px 4px rgba(200, 200, 200, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
    opacity: ${(props: StyledPropsContactBlock)=>props.isActivBlock ? '1' :  '0'};      
    animation: 1s ${(props: StyledPropsContactBlock)=>props.isActivBlock && openDiv }; 
        
    /*SCROLLBAR*/
    ::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
    
    }
    ::-webkit-scrollbar-track {
        background: #6A645A;        /* color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {    
        /* color of the scroll thumb */
        background-image: -webkit-linear-gradient(top,
                            #2f2c09 0%,                         
                            #bba803 100%);
        /* border-radius: 5px; */       /* roundness of the scroll thumb */
        border: 3px solid #6A645A;  /* creates padding around scroll thumb */
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
        
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background-image: -webkit-linear-gradient(top,
                            #bba803  0%,                         
                            #f8fc2d 100%); 
    }
    /*Firefox*/

    scrollbar-width: none; /* "auto" or "thin" */
    scrollbar-color:  #2f2c09 #bba803;   /* scroll thumb and track */


    
  
`
export const ProjectBlock = styled.div<StyledPropsInfoBlock>`
    width: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? props.blockWidth :  '0'};
    height: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? 'fit-content' :  '0'};
    min-height: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? '30em' :  '0'};
    overflow-y: scroll;
    overflow-x: hidden;    
    text-align: center ;
    margin: 0 auto;
    margin-top: ${(props: StyledPropsInfoBlock)=>props.marginTop};
    position: absolute;    
    transform-origin: top;
    align-self: center;
    background-color: rgba(106, 100, 90, 1);
    border-style: solid;
    border-width: 1px;
    border-color: #A1A27E;
    box-shadow: 0 2px 4px rgba(200, 200, 200, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
    opacity: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? '1' :  '0'};      
    animation: 0.8s ${(props: StyledPropsInfoBlock)=>props.isActivBlock && openDiv }; 
        
    /*SCROLLBAR*/
    ::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
    
    }
    ::-webkit-scrollbar-track {
        background: #6A645A;        /* color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {    
        /* color of the scroll thumb */
        background-image: -webkit-linear-gradient(top,
                            #2f2c09 0%,                         
                            #bba803 100%);
        /* border-radius: 5px; */       /* roundness of the scroll thumb */
        border: 3px solid #6A645A;  /* creates padding around scroll thumb */
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
        
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background-image: -webkit-linear-gradient(top,
                            #bba803  0%,                         
                            #f8fc2d 100%); 
    }
    /*Firefox*/

    scrollbar-width: none; /* "auto" or "thin" */
    scrollbar-color: #2f2c09 #bba803; /* scroll thumb and track */


    
    @media screen and (max-width: 992px){
        
        height: 15em;
    }
`


export const openDiv = keyframes `
    0% { 
        opacity:0;         
        transform: scale(0,0);
    }    
    50%{
        opacity: 1;        
        transform: scale(1.5,0);
    }
    75%{
        opacity: 1;        
        transform: scale(1,0);
    }
    100% { 
        opacity:1;       
        transform: scale(1,1);
     }
`


export const InfoElement = styled.div<StyledPropsInfoDiv>`
    width: 100%;
    height: fit-content; 
    display: block;   
    opacity: ${(props: StyledPropsInfoDiv)=>props.isActiv ? '1' :  '0'};
    
`


/**
 * To Wrap the two div
 */
 export const Wrapper = styled.div`
 
 display: block;
 position: relative;
 width: 100%;
 height: fit-content;

 @media screen and (max-width: 992px){  
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    
}

`
export const StyledApp = styled.div`   
    touch-action: ${(props:AppProps)=>props.disableTouch ? 'none' : 'auto'};
    overflow-y: ${(props:AppProps)=>props.disableTouch ? 'hidden' : 'auto'};
    overflow-x: hidden;
    z-index: -10000000;
    `
/**
 * To Wrap the two div - including nav
 */
 export const CarouselContainer = styled.div`
 
 display: grid;
 grid-template-columns: 90% 10% ;
 background-color: #EED7C5;
 height: fit-content;


 @media screen and (max-width: 992px){  
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    
}

`
export const CarouselnavWrapper = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: inherit;
    align-self: center; 
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  @media screen and (max-width: 992px){  
    display: none; 
}
`


/**
 * To Wrap the two div
 */
 export const MaskContainer = styled.div`
 
 display: grid;
 grid-template-rows: 75% 25% ;
 background-color: #EED7C5;
 height: fit-content;


 @media screen and (max-width: 992px){  
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    
}

`

/**
 * To Wrap the two div
 */
 export const PhoneWrapper = styled.div<StyledPropsSection>`
 position: relative;
 height: fit-content;
 width: 100vw;
 max-width: 100vw;
 @media screen and (max-width: 992px){
 
  display: block;
  height: fit-content;
  width: 100vw;
  max-width: 100vw;
}

`

/**
* Left div (animate enter slide left to right)
*/
export const LeftWrapper = styled.div`
 display: block;
 margin: 0 auto;
 width: 100%;
 height: inherit;
 position: relative;
 
 
`

export const RightWrapper = styled.div`
margin: 0 auto;
width: 100%;
height: 100%;
display: block;
-webkit-align-self: center;
-ms-flex-item-align: center;
align-self: center;
padding-top: 3em;

 
`

export const SectionDiv = styled.div<SectionProps>`
height: fit-content;
min-height: fit-content;
width: 100%;
max-width: 100vw;
background-color: ${(props:SectionProps)=>props.sectionId === 0 ? "#EED7C5" : "#FAF2EA"};
overflow: hidden;

`

export const BckgPosition = styled.div<SectionProps>`
    z-index: -2000000;
    background-color: #FAF2EA;
    position: absolute;
    top: ${(props: SectionProps)=>props.topPosition+'px;'};
    left:0;
    display: block;
    width: inherit;
    height: ${(props: SectionProps)=>props.divHeight+'px;'};
`
export const PaperHouse = styled.div<StyledProps>`
margin: 0;
position: relative;
width: 100vw;
transform: scale(0.75);
height: ${(props: StyledProps)=> props.propStyled ? '70vh' : '0'};
opacity: ${(props: StyledProps)=> props.propStyled ? '1' : '0'};
transition-property: height, opacity;
transition-duration: 1s, 1.5s;
transition-timing-function: ease-in, ease-out;
top: 0;
left: 0;
`
export const StyledImg= styled.img <StyledProps>`
 ${(props: StyledProps) => props.propStyled ? 'block'  : 'none' };
 width: 100%;
 height: auto;
`

/**
 * Navigation
 */
export const NavBckg = styled.div`
    position: fixed;
    background-color: black;
    opacity: 0.5;
    width: inherit;

`

export const NavUl  = styled.ul`
    background-color: rgba(250,250,250, 0.8);    
    position: fixed;    
    z-index: 1000000;
    margin: 0 auto;
    top: 0;
    left:0;    
    width: 100%;
    height: 5em;
    display: grid;  
    grid-template-columns:  20% 70%;
    grid-column-gap: 1%;
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0 2px 3px rgba(237,67,83, 0.4), 0 6px 20px rgba(237,67,83, 0.5); 
    @media(max-width: 992px){
        position: fixed;
        display: block;
        box-shadow: 0 0px 0px rgba(237,67,83, 0.4), 0 0px 0px rgba(237,67,83, 0.5);     
        padding: 0;
        width: 100vw;
        text-align: center;
    }
    
`
export const ShowHideBlock = styled.div <StyledProps>`
    display: grid;   
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));    
    grid-column-gap: 6%;
   
        @media(max-width: 992px){
            display: ${(props: StyledProps) => props.propStyled ? 'block'  : 'none' };
            box-shadow: 0 2px 3px rgba(237,67,83, 0.4), 0 6px 20px rgba(237,67,83, 0.5); 
            background-color: rgba(255,255,255, 0.8)
    }
`
export const ShowHideSide = styled.div <StyledProps>`
    background-color: rgba(250,250,250,0.8);
    display: inline;
    position: fixed;   
    right: 0;
    top: 10em;
    z-index: 50000;
    @media(max-width: 992px){
       top: 5em;
}
                     
`

export const NavLi = styled.li<NavLiProps>`

text-align: center;
margin: 0 auto;
list-style:none;
line-height: normal;
align-self: center; 
color : ${(props: NavLiProps)=>props.isActivSection ? '#F0604D' : 'black'} ;
transform: scale(${(props: NavLiProps)=>props.isActivSection ? "1.25" : "1"});

&:hover {
    border-style: none;
    color : #F0604D;
    cursor: pointer;
    transform: scale(1.5);
}
@media(max-width: 992px){   
    margin-top: 1em;    
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 0;

}

`
export const NavLogo = styled.div`
display: block;
align-self: center;
-webkit-align-self: center;
list-style: none;
@media(max-width: 992px){    
    &:hover{
        border-bottom: solid 2px #eac0b0;
        cursor: pointer;
    }
  }
`
export const MenuText = styled.span <StyledPropsNav> `
color: grey;
display: none;
@media(max-width: 992px){
    display: ${(props: StyledPropsNav)=>props.phoneNav ? 'none' : 'inline-block;'}
}
`

/**SIDE NAV */



export const Rightblock  = styled.ul`
    display: flex;    
    text-align: center;    
    font-family: 'Montserrat', sans-serif;
    @media(max-width: 992px){
        margin:0;
        padding:0;
        display: block ;
        text-align: center;
        margin-top: 2em;
        height: 5em;
    }
    
`

export const _Rightblock  = styled.ul`
    display: grid;  
    grid-template-columns: 80% 10%;
    grid-column-gap: 10%;
    text-align: center;    
    font-family: 'Montserrat', sans-serif;
    @media(max-width: 992px){
        display: block ;
        margin-top: 2em;
        height: 5em;
    }
    
`

export const SideNavUl  = styled.ul`
    display: block;    
    font-family: 'Montserrat', sans-serif;
    @media(max-width: 992px){
        display: block ;
        margin-top: 2em;
    }
    
`


export const SideNavLi = styled.li`

text-align: left;
list-style:none;
line-height: normal;

align-self: right;
padding-bottom: 0.8em;
transform: scale(0.75);
width: fit-content;
border-style: none;
border-left: solid 2px whitesmoke;

&:hover {
    border-style: none;
    border-left: solid 2px #F0604D;   
    cursor: pointer;
    transform: scale(1);
}
@media(max-width: 992px){   
    margin-top: 1em;
}

`
export const SideNavKey = styled.li`

text-align: left;
list-style:none;
line-height: normal;

align-self: right;
padding-bottom: 0.8em;

width: fit-content;
border-style: none;


&:hover {
    border-style: none; 
    cursor: pointer;
    

}
@media(max-width: 992px){   
    margin-top: 1em;
}

`
