
import "../styles/App.css";
import { DIPLOMAS, EXPERIENCE, SKILLS, INFORMATION, SECTIONS } from '../data/data';
import React, { Component, Suspense } from "react";
import {InfosDetails} from "../components/InfosDetails";
import AnimatedButtons from "../components/AnimatedButtons";
import SuspenseWebBowl from "../components/SuspenseWebBowl";

const infoImports = require.context("../../public/Anims/Info/", true, /\.png|jpe?g$/);
const skillsImports = require.context("../../public/Anims/Skills/", true, /\.png|jpe?g$/);
const diplomasImports = require.context("../../public/Anims/Diplomas/", true, /\.png|jpe?g$/);
const experienceImports = require.context("../../public/Anims/Experience/", true, /\.png|jpe?g$/);
const compassImports = require.context("../../public/Anims/Compass/", true, /\.png|jpe?g$/);

const InfoPath = infoImports.keys().map(myImport=> myImport.replace("./", "Anims/Info/"));
const SkillsPath = skillsImports.keys().map(myImport=> myImport.replace("./", "Anims/Skills/"));
const DiplomasPath = diplomasImports.keys().map(myImport=> myImport.replace("./", "Anims/Diplomas/"));
const ExperiencePath = experienceImports.keys().map(myImport=> myImport.replace("./", "Anims/Experience/"));
const CompassPath = compassImports.keys().map(myImport=> myImport.replace("./", "Anims/Compass/"));


interface InfosProps {
    id: number,
    sectionid: number,
    title: string,
    date: string,
    description: string,
    level: string    
}
interface AboutProps{
    
}

interface ImgProps{
    id: number,
    url: string,
    sectionId: number
}
interface AnimatedSection{        
        id: number,
        isToLaunch: boolean,
        images: ImgProps[],
        title: string
}


export default class About extends Component <AboutProps>{
    infos: InfosProps[] | undefined;
    sectionsForRenderTime: AnimatedSection[] | undefined
    timerDelay: any;
    sectionRefs: (HTMLButtonElement | null)[];
    

    constructor(props: AboutProps){
        super(props);
        this.timerDelay = React.createRef<any>();
        this.sectionRefs = [];
    }
    state = {
        activSectionId: null,
        prevSectionId: -1,
        infos:[],       
        isLeft: false,
        sectionsForRenderTime: this.prepareSectionsForRender(),
        clickedId: -1,
        oldId: -1,
        sameId: null,
        sectionRefs: []

        
    }
  

   
    
   
    
   

  componentWillUnmount(){
    if (this.timerDelay){
        clearTimeout(this.timerDelay.current);  
    }
  } 



 setImagesToRender(pSectionId: number){
    let imgTable: string[] | any[] = [];
    switch (pSectionId) {
        case 0:
            imgTable = DiplomasPath; // diplomas
            break;
        case 1:
            imgTable = ExperiencePath;
            break; 
        case 2:
            imgTable = InfoPath; // info
            break;
        case 3:
            imgTable = SkillsPath; // skills
            break;
        default:
            break;
    }
  let table : ImgProps[];
  table = []; 
    for (let i = 0 ; i < imgTable.length; i++){        
        table.push({
            id: i,
            url: imgTable[i],
            sectionId: pSectionId        
        })
    }
    
    return table;
}
 setImagesToPass(pSectionId: number){
    let imgTable: string | any[] = [];
    switch (pSectionId) {
        case 0:
            imgTable = DiplomasPath; // diplomas
            break;
        case 1:
            imgTable = ExperiencePath;
            break; 
        case 2:
            imgTable = InfoPath; // info
            break;
        case 3:
            imgTable = SkillsPath; // skills
            break;
        default:
            break;
    }  
    
    return imgTable;
}

 smoothScroll(id: number|null, sTarget: string){
        /**
         * Récupère l'id de la section d'info - si actif, scroll
         */
        console.log("dans smoothScroll" + id+sTarget);       
        const sectionToGo = document.getElementById(id + sTarget);        
        if (sectionToGo !== null){
            console.log(sectionToGo);            
            sectionToGo.scrollIntoView(
                {
                    block: 'center',
                    behavior: 'smooth'
                   
                  
                }
            );
            
            }
}

 ShowSection = (sectionId: number)=>{ 
 
    const activToggle = this.ToggleSection(sectionId);    
    this.setState({activSectionId: activToggle},()=>{
    // alignement div sections
    if (activToggle === 0 || activToggle === 1 ){
        this.setState({isLeft: true});
    }else{
        this.setState({isLeft: false});
    }
    this.setState({infos: this.ChooseInfos(sectionId)});
    
    let currentSection : HTMLButtonElement | null;        
        if ( this.sectionRefs){  
            if( this.state.activSectionId !== null && this.state.activSectionId !== undefined){
            console.log("section ref : ", this.sectionRefs[sectionId]) 
            currentSection = this.sectionRefs[sectionId];          
            if (currentSection){
                this.timerDelay.current = setTimeout(
                () =>{
                    this.smoothScroll(this.state.activSectionId, '-targetBtn');               
                }, 500);
            }
            }else{
                console.log("on passe dans currentsection null");
                this.timerDelay.current = setTimeout(
                    () =>{
                        this.smoothScroll(null, 'animated-buttons');               
                    }, 500);
            }
        
    }
});  
       
       
}

     ToggleSection = (pSectionId: number | null | undefined) : number | null | undefined =>{   
           
        return this.state.activSectionId === pSectionId ? null : pSectionId;        
    }
  
     ChooseInfos = (pActivId: number|null) : InfosProps[] | [] =>{
        switch (pActivId) {            
            case 0:
               return DIPLOMAS;
            case 1:
                return EXPERIENCE;
            case 2: 
                return INFORMATION;
            case 3:
                return SKILLS;
            default:
                return [];
        }
    }
   
   

    
     handleCompassClick = (e: MouseEvent, pSectionId: number|null) =>{
        e.preventDefault(); 
        console.log("on rentre dans handlecompassclick");
        
        const activSectionId = this.state.activSectionId;
        // go section forward / backward
        if (activSectionId !== null && activSectionId !== undefined){ 
        if (activSectionId < SECTIONS.length){
            console.log("section id : " , activSectionId);
            this.setState({activSectionId: activSectionId + 1});            
        }else{
            this.setState({activSectionId: 0});           
        }
        
        // align div elements by sectionID
           if (activSectionId === 0){              
               this.setState({isLeft:true});
            }
            if (activSectionId === 1){               
                this.setState({isLeft:false});
             }
             if (activSectionId === 2){              
                this.setState({isLeft:false});
             }
             if (activSectionId === 3){                
                this.setState({isLeft:true});
             }
    }else{       
        this.setState({isLeft:true});
    }
}
  

    
     handleSectionToLaunch = (pSectionId: number) =>{
        let newArray: AnimatedSection[] = this.state.sectionsForRenderTime;
        // reset isToLaunch before launch
        newArray.forEach(section => {
            section.isToLaunch = true;//false
        });
        const sectionToLaunch = this.state.sectionsForRenderTime.find(
            section=> section.id === pSectionId);
        if (sectionToLaunch !== undefined){
            sectionToLaunch.isToLaunch = true;
            newArray[sectionToLaunch.id] = sectionToLaunch;
            this.setState({sectionsForRenderTime:newArray});
            
        }
       
    }
 
      
/**
 * Load sections and images 
 * sets a new table of animated sections with id, images and isToLaunch
 */

     prepareSectionsForRender (): AnimatedSection[]{
        let allImagesBySection:AnimatedSection[] = [];
        SECTIONS.forEach(section => {
            const myImgTable = this.setImagesToRender(section.id);
            const toLaunchOrNot: AnimatedSection = {
                id: section.id,
                title: section.title,
                isToLaunch: true, // init false
                images: myImgTable
        }
            allImagesBySection = [...allImagesBySection, toLaunchOrNot];
            
        });        
        return allImagesBySection;
        
    }



    render() {
        const {activSectionId, sectionsForRenderTime, clickedId, oldId, sameId, isLeft, infos} = this.state

        return (
            
        <>
 
        
        <div className="center-div"> 
        <div className="frame-sections">
            <SuspenseWebBowl/>
        </div>      
               
            <h1 className="title2">Mon parcours</h1>                        
             
        </div>

        <div className="about-sections">
            <AnimatedButtons
                
                buttonsId="animated-buttons"
                onClick= {this.ShowSection}/> 
        </div>
        
      
            <div className="info-div">
            {sectionsForRenderTime && sectionsForRenderTime.map(section=>  
           
            
            <InfosDetails
                id={section.id}
                ref={(sectionRef: any) => this.sectionRefs[section.id] = sectionRef}
                key={section.id + "-details"}
                sectionTitle={section.title}
                isLeft={isLeft}
                infos={infos}
                images={CompassPath}
                isActivBlock={activSectionId === section.id ? true : false}
                activSectionId={activSectionId} 
                sectionid={section.id}                                       
            />
        
            )}
      
            </div>
  
</>
        )
    }
}


    
   