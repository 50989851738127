import React, { Component } from 'react';


 const defaultState = {
    letsGo: false,
    letsPlayPaperHouse: false,
    pointerIsMoving: false,
    arrowIsClicked: [{id: -1, isClicked: false}],   
    setLetsGo: (pLetsGo: boolean)=>{},
    setLetsPlayPaperHouse: (pLetsGo: boolean)=>{},
    setPointerIsMoving: (pMoveState: boolean)=>{},
    setArrowIsClicked: (id: number, pIsClicked: boolean)=>{},    
    initArrowSection: (total: number)=>{}   
    
};

  interface Iarrow{
    id: number;
    isClicked: boolean;
  }
  interface MyPropsInterface{
    children: React.ReactNode
  }
  const captchaOptions = {
    AccessControlAllowOrigin: document.location.href,          
    SameSite: 'None',
    Secure: true,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        "google-question" : "google-question"
      },        
}

const TheContext= React.createContext(defaultState);

//<RoomContext.Provider value={}

// Génère le contexte : c'est ici qu'on définit les valeurs
class TheProvider extends Component<MyPropsInterface> {
    recaptchaPhp: any;
    constructor(props: MyPropsInterface){
        super(props); 
    }

    state={
        letsGo : false,
        letsPlayPaperHouse: false,
        pointerIsMoving: false,
        arrowIsClicked: [],
       
     
    }
   
    // for compass anim
    setLetsGo=(pLetsGo: boolean)=>{
        this.setState({letsGo: pLetsGo})
    }
    // for paperHouse anim
    setLetsPlayPaperHouse=(pLetsPlay: boolean)=>{
        this.setState({letsPlayPaperHouse: pLetsPlay});
    }
    
    setPointerIsMoving = (pPointerState: boolean)=>{
        this.setState({pointerIsMoving : pPointerState});
    }
   
    setArrowIsClicked = (pId: number, pIsClicked: boolean)=>{ 
        const result = this.state.arrowIsClicked.map((anArrow: Iarrow)=>{
            if (pId === anArrow.id){
                return {id: pId, isClicked: pIsClicked};
            }else{
                return {id: anArrow.id, isClicked: anArrow.isClicked}
            }
        })
        this.setState({arrowIsClicked: result});
    }
   
    initArrowSection = (total: number)=>{
        // init table on load
        let table=[];                 
        for ( let i= 0; i <= total; i ++){
            const newData = {id: i, isClicked: false};
            table.push(newData);
        }
        this.setState({arrowIsClicked: table});
    }
  
   
   
    render() {
        
        return (           
            
            <TheContext.Provider value={{
                ...this.state,                    
                setPointerIsMoving: this.setPointerIsMoving,
                setLetsGo: this.setLetsGo,
                setLetsPlayPaperHouse: this.setLetsPlayPaperHouse,  
                setArrowIsClicked: this.setArrowIsClicked,              
                initArrowSection: this.initArrowSection
              
            }}>
            
            {this.props.children}
            </TheContext.Provider>
            
            );
        }
    }
    
   

    
const TheConsumer = TheContext.Consumer
export function withContext(Component: any){
    return function ConsumerWrapper(props:any){
        return <TheConsumer>
        {value => <Component {...props} context={value} />}
        </TheConsumer>
    }
}
    
    export {TheProvider, TheConsumer, TheContext};