import React, { useContext } from 'react'
import { MovingLoading } from '../common'
import { TheContext } from '../context/MyContext';

export default function FallBackFrame(props:{frame: string}) {
 const myContext = useContext(TheContext);
 
  interface pointInter{
   id: number;
   label: string;
 }
  const points = [{id : 1, label: "."}, {id : 2, label: "."}, {id : 3, label: "."}]
  return (
   
        <div className='house-frame-position'>
          <div className =  'info-position'> 
          <div className = 'points-position'>         
              {points.map((aPoint: pointInter)=>(               
               <MovingLoading key={aPoint.id} id={aPoint.id}>{aPoint.label}</MovingLoading>  
               
              ))}     
                    
              <div className='text-area'>
                <span className='spacy-span'>Chargement de l'animation</span>
              </div>
          </div>
          </div>
        {/* <img src={props.frame} alt="...chargement en cours"/> */}
        </div>
   
  )
}
