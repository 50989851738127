import { useEffect, useState } from "react"



interface ProjectData{
    id: number,
    title: string,
    description: string,
    icon: string
}


export default function InfosProjet (props: {projectData: ProjectData[] | null | undefined}) {
const [isPhoneDevice, setIsPhoneDevice] = useState(false);

// CompoDidMount
useEffect(() => {
    if (window.innerWidth < 992) {
        setIsPhoneDevice(true);
    }   
   }, []);
   

    return (
        <>       
       
        <div className="info-wrapper">           
            {props.projectData && props.projectData.map(item=>
            isPhoneDevice||item.id % 2 === 0 ? <div key={item.id} className="info-container">
            <div>
                <div className="info"><h2>{item.title}</h2></div>
                <div className="info-text-zone">{item.description}</div>
            </div>
            <div>
                <img width={'100%'} height={'auto'}  src={item.icon} alt={item.title}></img>
            </div>            
            </div>
            :
            <div key={item.id} className="info-container">
            
            <div>
                <img width={'100%'} height={'auto'}  src={item.icon} alt={item.title}></img>
            </div>
            <div>
                <div className="info"><h2>{item.title}</h2></div>
                <div className="info-text-zone">{item.description}</div>
            </div>
            </div> 
             
            )}               
        </div>  
        </>
    )
}
