import { MovingLoading } from '../common'
import CurvingTitle from './CurvingTitle';
import LoadingCurving from './LoadingCurving';


export default function SimpleFallBack({...props}) {
 
 
  interface pointInter{
   id: number;
   label: string;
 }

  const points = "............";
  return (
    <LoadingCurving title={points}/>    
  
  )
}
