
import Arrow from "../components/ArrowOcc";
import LogoEnBouyssou from "../components/LogoEnBouyssou";
import {navData} from "../data/data";
import { MenuText, NavLi, NavLogo, NavUl, ShowHideBlock } from '../common';
import "../styles/App.css";





interface NavProps {    
    onClick (id: number) : void,
    handleNavPhoneClick(): void,
    phoneNav: boolean,
    activSection: number,
    scrolledSection: number;
    
}
interface SvgProps{
    height: string,
    width: string
}

const Nav = (props: NavProps) =>{ 
    const ArrowNav : React.FC = () =>{        
            return <Arrow width="50px" height="50px"/>
    }
    
const LogoEbProps: SvgProps = {height: "50px", width: "50px"}
   
    return(
       <div className="nav-wrapper">
            <NavUl id="nav">
                <NavLogo onClick={props.handleNavPhoneClick}><LogoEnBouyssou {...LogoEbProps}/>
                <div><MenuText phoneNav={props.phoneNav}>Menu</MenuText></div> 
                </NavLogo>
                <ShowHideBlock propStyled={props.phoneNav}>
                    {navData && navData.map(nav=>                        
                    <NavLi 
                    onClick={()=>props.onClick(nav.id)}
                    isActivSection={props.scrolledSection === nav.id}
                    id={nav.id.toString()} 
                    key={nav.id + nav.label}>
                        {nav.label === "La Toile d'en Bouyssou" ? "Accueil" : nav.label}
                    
                    </NavLi>
                    
                    )}
                </ShowHideBlock>
            </NavUl>
            </div>
    )
}

export default Nav;

