import { headline } from '../data/data';
import AnimatedCard from "../components/AnimatedCard";

interface AnimatedItem{
    id: number,
    title: string,  
    info1: string,
    info2?: string,
  
}

export const ProjectDetails = (props:{cardsVisible: boolean}) =>{
        
    return (     
        <div id="cards-for-anim" key={headline.id + "wrapper-details"} className="wrapper-details">       
        {headline.infos.map((item: AnimatedItem)=>            
        
       <AnimatedCard 
        key={item.id}
        animKey={item.id}
        item={item && item}
        letsAnimCards={props.cardsVisible}
        
        />      
       
   
  
        )
}</div>)
}
