

import { useEffect, useRef, useState } from 'react';
import { TheContext } from '../context/MyContext';
import bckgCompass from "../images/bckg_compass.png"
import PlayRewind from './PlayRewind';
import {useContext} from 'react';




interface ImageProps{
    id: number,
    url: string,
   
}
/**
 * 
 * @param isSingleButton
 * @param isToggleButton 
 * @returns 
 */

export default function CompassBtn ({...props}) {
    const [go, setGo] = useState<boolean>(false); // compass anim
    const [activId, setActivId] = useState<number>(0);   
    const [imgTable, setImgTable]= useState<ImageProps[]|[]>(formatPlayData(props.images));
    const timerPlay = useRef<any>(null);
    const [time, setTime] = useState<number>(0);  
    const myContext = useContext(TheContext);
    

    const playAnim = (localImages: ImageProps[])=>{        
        const start = 0;            
        const end = localImages.length-1; 
                
     // tableau d'image play / rewind
     if (props.isActiv === true){        
        setImgTable(formatPlayData(props.images));         
    }else{                       
        setImgTable(formatRewindData(props.images));                           
    }
    for (let i = start; i <= end;  i++){                           
        timerPlay.current = setTimeout(
            function(){                
            setActivId(i);
            if ( i >= end){ // anim stop
            clearTimeout(timerPlay.current);
            setActivId(0);
            }
        }                     
, i * 30);
    }
}
   
    useEffect(() => {        
 
       if (myContext.letsGo){
           if (time < 1) { 
            playAnim(imgTable);
            myContext.setLetsGo(false); // stop
            }
        }
     
    }, [playAnim, imgTable])

    function formatPlayData(pSkillsPath: string[]){
        let images : ImageProps[];
        images = pSkillsPath && pSkillsPath.map((item, key)=>{                    
            // créé l'objet image à partir de l'item, 
            // y ajoute l'id créé par la key
            let image: ImageProps;
            image = {url: item, id: key}
            // pour chaque item on retourne une image
            return image; // push
        });        
        // on retourne la liste d'images  
        return images;
    }
    function formatRewindData(pSkillsPath: string[]){
        let reverseImages : ImageProps[];
        reverseImages = pSkillsPath && pSkillsPath.map((item, key)=>{                    
            // créé l'objet image à partir de l'item, 
            // y ajoute l'id créé par la key
            let image: ImageProps;
            image = {url: item, id: key}
            // pour chaque item on retourne une image
            return image; // push
        })
        // on reverse le tableau
        reverseImages.reverse();
        // on retourne la liste inversée     
        return reverseImages;
    }

    const ToggleClick: VoidFunction = () =>{  
        console.log("click from compass");
            setTime(0); // reset nb fois play anim in use effect
            //setPrevClickState(false); // reset contact click
            console.log( "compassBtn form isActiv : " , props.isActiv)
            go === true ? setGo(false) : setGo(true);
         
    }

  


    return (
        <div className="compass-div">                 
                <PlayRewind
                className="img-compass"
                onClick={ ()=>{
                   //ToggleClick()
                   props.onClick()
                }}   
                imgTable={imgTable}
                activId = {activId}
                background = {bckgCompass}
               
            /> 
           
        </div>
        
    )
}

