import React from 'react';



interface ArrowProps{
    width: string;
    height: string;
}

const Arrow : React.FC <ArrowProps> = (props: ArrowProps) =>{

    return (


<svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width={props.height} height={props.width} viewBox="0 0 500 500" enableBackground="new 0 0 500 500" xmlSpace="preserve">
<g id="Cross">
	<g>
		
			
		<g id="g4277" transform="matrix(4.0000001,0,0,4.0000001,0,-450.40006)">
			<g id="g5">
				<path id="path7"  fill="#FFD802" d="M72.277,137.247c-1.677,0-3.089,1.302-3.089,2.939v69.57
					c0,1.595,1.369,2.94,3.089,2.94c1.72,0,3.088-1.302,3.088-2.94v-69.57C75.364,138.59,73.953,137.247,72.277,137.247
					L72.277,137.247z"/>
				<path id="path9" fill="#FFD802" d="M85.776,142.327c-1.677,0-3.088,1.302-3.088,2.939v59.409
					c0,1.595,1.367,2.939,3.088,2.939c1.721,0,3.089-1.302,3.089-2.939v-59.409C88.821,143.67,87.453,142.327,85.776,142.327z"/>
				<path id="path11"  fill="#FFD802" d="M99.232,153.537c-1.676,0-3.088,1.302-3.088,2.939v36.989
					c0,1.595,1.368,2.939,3.088,2.939c1.721,0,3.088-1.302,3.088-2.939v-36.989C102.32,154.839,100.953,153.537,99.232,153.537z"/>
				<path id="path15"  fill="#FFD802" d="M66.762,129.479c0.573-0.756,0.926-1.68,0.926-2.687
					c0-2.519-2.161-4.577-4.808-4.577c-2.647,0-4.809,2.058-4.809,4.577c0,1.176,0.485,2.267,1.235,3.065
					c-4.146,4.786-9.618,7.683-11.735,8.691c-0.751-1.638-2.427-2.771-4.457-2.771c-2.646,0-4.809,2.057-4.809,4.576
					c0,2.519,2.162,4.576,4.809,4.576c1.104,0,2.074-0.336,2.912-0.923c5.339,7.893,6.839,17.13,7.28,21.749
					c-4.852-0.378-14.603-1.805-22.809-6.886c0.574-0.756,0.927-1.679,0.927-2.687c0-2.519-2.162-4.576-4.808-4.576
					c-2.647,0-4.809,2.057-4.809,4.576c0,1.89,1.235,3.527,2.956,4.241c-1.191,2.309-4.676,8.271-9.485,11.378
					c-0.882-0.882-2.117-1.47-3.529-1.47c-2.647,0-4.809,2.057-4.809,4.576s2.161,4.576,4.809,4.576
					c1.412,0,2.647-0.546,3.529-1.469c4.809,3.107,8.294,9.11,9.485,11.42c-1.721,0.713-2.956,2.309-2.956,4.24
					c0,2.519,2.162,4.576,4.809,4.576c2.646,0,4.808-2.057,4.808-4.576c0-1.008-0.353-1.932-0.927-2.688
					c8.206-5.08,17.957-6.507,22.809-6.885c-0.397,4.618-1.897,13.813-7.28,21.664c-0.794-0.588-1.808-0.924-2.912-0.924
					c-2.646,0-4.809,2.057-4.809,4.576s2.162,4.576,4.809,4.576c1.986,0,3.706-1.134,4.457-2.771
					c2.117,1.008,7.544,3.905,11.735,8.691c-0.794,0.798-1.279,1.889-1.279,3.065c0,2.519,2.161,4.576,4.808,4.576
					c2.647,0,4.809-2.057,4.809-4.576c0-1.008-0.353-1.931-0.926-2.687c-4.147-6.76-10.809-11.084-14.691-13.184
					c7.324-11.924,7.28-25.443,7.28-26.031v-2.771l-2.912-0.042c-0.662,0-14.823-0.042-27.397,6.928
					c-1.633-2.813-4.5-7.096-8.426-10.329c3.926-3.191,6.794-7.473,8.426-10.286c12.529,6.969,26.735,6.927,27.397,6.927
					l2.912-0.042v-2.771c0-0.63,0-14.107-7.28-26.031C55.997,140.605,62.659,136.281,66.762,129.479L66.762,129.479z"/>
			</g>
		</g>
	</g>
	<circle fill="#FFD802" cx="451.811" cy="250" r="16.929"/>
</g>
</svg>
    )
}
export default Arrow;

