import { useState } from "react";
import styled from "styled-components";





const ZoomDiv = (props: {letsZoom: boolean, isLeft: boolean | undefined, path: string, altInfo: string, onClick: (e: MouseEvent)=>void,  index: number})=>(
     
    <ZoomImage
        isLeft={props.isLeft ? props.isLeft: false}     
        letsZoom={props.letsZoom}
        alt="zoomImage" 
        src={props.path}
        onClick={props.onClick}
       
    />

)

export const ImgGallery =(props:{path: string | undefined, index: number})=>{
    const imgGalleryRightPosition = document.getElementById(props.index+"gallery-img")?.getBoundingClientRect().right;
    const middleWindow = window.innerWidth/2;
    const isLeft = imgGalleryRightPosition && imgGalleryRightPosition < middleWindow
    const myStringTable = altInfo(props.path);
    const [clickedImgId, setClickedImgId] = useState<number>(-1);

    const handleMouseOver = (e: MouseEvent, id: number) =>{
        e.preventDefault();
        if(e.target)
        console.log("e target " , e);           
        setClickedImgId(id);        
        console.log("mouseOver : ", id);
        console.log("clickedImg : ", clickedImgId);
    }
    const handleTouchStart = (e: TouchEvent, id: number) =>{
        e.preventDefault();
        if(e.target)
        console.log("e target " , e);           
        setClickedImgId(id);        
        console.log("mouseOver : ", id);
        console.log("clickedImg : ", clickedImgId);
    }

    const handleMouseLeave = (e: MouseEvent, id: number) =>{
        e.preventDefault();     
       
        if(clickedImgId !== -1) setClickedImgId(-1);
        console.log("mouseLeave : ", id);
        console.log("mouseLeave clickedImg : ", clickedImgId);
    }

    const handleTouchEnd = (e: TouchEvent, id: number) =>{
        e.preventDefault();       
        if(clickedImgId !== -1) setClickedImgId(-1);
       
    }

    return (
       
        <ImgContainer
        onMouseEnter={(e: MouseEvent)=>{
            handleMouseOver(e, props.index);
        }}
        onMouseLeave={(e: MouseEvent)=>{
            handleMouseLeave(e, props.index);
       }}
       onTouchStart={(e: TouchEvent)=>{
        handleTouchStart(e, props.index);
        }}
        onTouchEnd={(e: TouchEvent)=>{
            handleTouchEnd(e, props.index);
        }}
        >
        <ZoomDiv
        isLeft={isLeft ? isLeft : false}
        index={props.index}
        letsZoom={props.index === clickedImgId}
        path={props.path ? props.path : ""} 
        altInfo={myStringTable ? myStringTable[props.index]: ""}        
        onClick={(e: MouseEvent)=>{      
            console.log("clickedDiv : ", props.index);
            setClickedImgId(clickedImgId === props.index ? -1 : props.index);
        }}
    />
    
    <StyledImgGallery
        id={props.index+"gallery-img"}
        index={props.index}
        src={props.path} 
        alt={myStringTable && myStringTable[props.index]} 
     
    />
   
    
    </ImgContainer>
   
    )
    }
export const altInfo = (path: string|undefined): string[] | undefined | ""=>{
    
    const formattedInfo = path && path.split("_");
    if (formattedInfo){
        const infos = formattedInfo.map((info:String, index: number)=>{
            return info;
        }
        )
    }
    return formattedInfo;

}
export const JsxInfo = (props: {path: string|undefined})=>{
        const altInfos = altInfo(props.path);
        return(
        <div>
            {altInfos && altInfos.map((info: string, index: number)=>(
                <span>{info}</span>
                
            ))}
        </div>
       
)}



interface StyledImgProps{
    letsZoom: boolean;
    isLeft: boolean;
}

const StyledImgGallery = styled.img`
    z-index: 2000;
    cursor: zoom-in;
    cursor: -webkit-zoom-in;
    width: auto;
    height: 200px;
    object-fit: cover;   
    border-style: none;
    background-color: #696969;
    margin: 0 auto;
    border-style: solid;
    color: rgba(245,239,230);
    position: relative;
    @media(max-width: 800px){
      
        height: 75px;
        
    }
`
const ImgContainer = styled.div`
    
    width: auto;
    height: 200px;
    background-color: #696969;
    margin: 0 auto;  
    position: relative;
    display: inline-flex;
    @media(max-width: 800px){
      
        height: 75px;
        
    }
`
const ZoomImage = styled.img<StyledImgProps>`

    opacity: ${(props: StyledImgProps)=>props.letsZoom ? "1" : "0"};
    z-index: ${(props: StyledImgProps)=>props.letsZoom ? "20000" : "0"};
    width: auto;
    height: 20em;
    object-fit: cover;   
    border-style: none;
    background-color: #696969;
    margin: 0 auto;
    border-style: solid;
    color: rgba(245,239,230);
    position: absolute;
    top:0;
    right: ${(props: StyledImgProps)=>props.isLeft ? "null" : "0"};
    left: ${(props: StyledImgProps)=>props.isLeft ? "0" : "null"};
    transition-property: z-index, opacity;
    transition-duration: 1s;  
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
    -webkit-transition: z-index, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    @media(max-width: 800px){
        width: auto;
        height: 15em;
        
    }
`
