import "../styles/App.css";
import React, { Suspense } from "react";
import FallBackFrame from "../components/FallBackFrame";
import SuspenseWebBowl from "../components/SuspenseWebBowl";
const LazyFrame = React.lazy(()=>import('../components/Frame'));




export default function DevWeb() {  

  
   
    
    return (
        <div id="animatedCardsWrapper">
       
        <button className="invisiblebutton"></button>
     
        <div className="frame-sections">
            <SuspenseWebBowl/>
        </div>
              <h1 className="title2">
                La Toile d'en Bouyssou vous propose sa 
                <span> Plateforme de réservations</span>
            </h1>
        
        
        </div>
        
    )
}
