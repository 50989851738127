import React, { Suspense, useEffect, useRef, useState } from 'react';
import { BlockContent, PriorityBlock } from '../common';
import "../styles/HorizontalScrollSnap.css";
import {bottles} from "../data/data";
import FallBackFrame from './FallBackFrame';
import ArrowAlone from './ArrowAlone';

const LazyFrame = React.lazy(()=>import('./Frame'));

interface BlockData{
    id: number,
    title: string,
    description: string,
    icon: string,
    background: string
}

interface AnimElt{
    id: number,
    isToAnimate: boolean
}
/* interface ArrowProps{
    color: string,
    height: number,
    width: number,
    isLeft: boolean,
    className: string
} */


export default function HorizontalScrollSnap(props: {blockData: BlockData[], blockIsScrollingX: boolean}) {

    const timerAutoScroll = useRef<any>(null);
    const timerStopAutoScroll = useRef<any>(null);

    const [activPresta, setActivPresta] = useState<number>(0);
    const [priorityIds, setPriorityIds] = useState<number[]>([]);
    const activPrestaRef = useRef(activPresta);    
    activPrestaRef.current = activPresta;  
    
    const scrollRefs: (HTMLButtonElement | null)[] = [];
    const scrolledBlock = document.getElementById("scroll-block");
    const rightArrowProps = {color:"#212E53", className:'arrow-card', isLeft:false, width:'50px', height:'50px'};
    const leftArrowProps = {color:"#212E53", className:'arrow-card', isLeft:true, width:'50px', height:'50px'}

    //swipe
    const[swipeStartPosition, setSwipeStartPosition] = useState<number|null>(null);
    const[mouseStartPosition, setMouseStartPosition] = useState<number|null>(null);
    const[endPosition, setEndPosition] = useState<number|null>(swipeStartPosition);
    /**compoMount */
    useEffect(()=>{
        const priorityTable = props.blockData.map((aData: BlockData)=>{
            return aData.id;
        });
        setPriorityIds(priorityTable);
    },[])
    
    useEffect(() => {
     
        return () => {           
          
            if (timerAutoScroll.current){
                clearTimeout(timerAutoScroll.current);
            }
            if(timerStopAutoScroll.current){
                clearTimeout(timerStopAutoScroll.current);
            }            
            
        }
    });

    

  

function initAnimArray(): AnimElt[]{
    const animatedArray = props.blockData.map(elt=>{
        const animatedElt = {id: elt.id, isToAnimate: false}
        return animatedElt;
    });
        return animatedArray;
}
const handleMouseDown=(mouseDownEvent: React.MouseEvent<HTMLDivElement, MouseEvent>): void=> {
    console.log("mouseDown");
    const posStart = mouseDownEvent.clientX;
    setMouseStartPosition(posStart);
    console.log("position start mouse : ", posStart);
}

const handleMouseUp=(mouseUpEvent: React.MouseEvent<HTMLDivElement, MouseEvent>): void=>{
   console.log("handle mouse up");
   let change = mouseUpEvent;   
   
       if (mouseStartPosition){
    if(change.clientX > mouseStartPosition){       
        console.log("go left : ", change.clientX);
        goNext();
    }else{
        console.log("go right : ", change.clientX);
        goPrevious();
    }

   } 
}


function handleMouseLeave(): void {
    console.log("handle mouse leave");
}

const handleTouchStart=(touchStartEvent: React.TouchEvent<HTMLDivElement>): void=> {
    // recup position touchStart
    //touchStartEvent.preventDefault();
    const posStart = touchStartEvent.touches[0].clientX;
    setSwipeStartPosition(posStart);
    console.log("position start : ", posStart);
    /* 
    throw new Error('Function not implemented.'); */
}

const handleTouchMove=(touchMoveEvent: React.TouchEvent<HTMLDivElement>)=>{
   
   /* let changes = touchMoveEvent.changedTouches;
   
   for (let i = 0; i<changes.length; i++){
       if(startPosition){
    if(changes[i].clientX > startPosition){       
        console.log("go left : ", changes[i]);
        clickNext();
    }else{
        console.log("go right : ", changes[i]);
        goPrevious();
    }

   } 
} */
}

const handleTouchEnd=(touchEndEvent: React.TouchEvent<HTMLDivElement>)=>{
    let changes = touchEndEvent.changedTouches;
   if(changes){
   for (let i = 0; i<changes.length; i++){
       if (swipeStartPosition){
    if(changes[i].clientX > swipeStartPosition){       
        goNext();
    }else if (changes[i].clientX < swipeStartPosition){        
        goPrevious();
    }
}

   } 
}
    console.log("handle mouse touch end");
}
function goPrevious(){
    const tempPrior = priorityIds.map((priorityId: number)=>{
        return priorityId !== props.blockData.length-1 ?  priorityId + 1 : 0;
    })
    setPriorityIds(tempPrior);
    console.log("click prev : " , tempPrior);
}
function goNext(){
    const tempPrior = priorityIds.map((priorityId: number)=>{
        return priorityId === 0 ?  props.blockData.length-1 : priorityId - 1;
    })
    setPriorityIds(tempPrior);
    console.log("click next : " , tempPrior);
}

const clickPrevious =(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    e.preventDefault();
    goPrevious();
    
}
const clickNext =(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
   e.preventDefault();
   goNext();
}

    /**
     * 
     */
 

    
    return (
        <>
        
        <div className='go-block'>
        
        <div id="scroll-block" className='scrolling-block'
        onScroll={(e)=>{console.log("I'm scrolling")}}
        onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
        onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
        onTouchEnd={(e) => handleTouchEnd(e)}         
        
        >
       
        <div className='elts-block'>
                {props.blockData.map(data=>
                    <PriorityBlock dataId={data.id} numberOfItems={props.blockData.length-1} priorityId={priorityIds[data.id]} key={data.id} id={data.id+"-presta"}>
                        <div className={priorityIds[data.id] === 0 ? "block-img" : "no-block-img"}>
                        <div className='block-icon'>
                            <span>{data.title}</span>
                        </div>
                        <React.StrictMode>
                            {data.background !== "" && <img className='block-background'src={data.background} alt={data.title}/>}
                            {data.id === 3 && <div className="animation-frame">
                            <Suspense fallback={<FallBackFrame frame={bottles[0]}/>}>
                                <LazyFrame 
                                id={data.id} 
                                frames={bottles} 
                                letsAnim={priorityIds && priorityIds[data.id] === 0 ? true : false}
                                />
                            </Suspense>                                                        
                                </div>}
                            </React.StrictMode>
                            
                        </div>
                        <BlockContent id={"birth-elt-"+ data.id} canAppear={priorityIds && priorityIds[data.id] === 0 ? true : false} >
                            <h1 className='block-title'>{data.description}</h1>
                        </BlockContent>
                    </PriorityBlock> 
                                 
                )}
        </div>
        <div className='buttons-block'>
                <button className='scroll-buttons' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{clickPrevious(e);}}>
                    <ArrowAlone {...leftArrowProps}/>
                </button>    
                <button className='scroll-buttons' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{clickNext(e)}}>
                    <ArrowAlone {...rightArrowProps}/>
                </button>
        </div>
        </div>
        
        </div>
        
        </>
    )
}

