import React from 'react';

import ServiceArticle from '../components/ServiceArticle';
import SuspenseWebBowl from '../components/SuspenseWebBowl';
import { servicesData, savData } from '../data/data';

const LazyFrame = React.lazy(()=>import('../components/Frame'));



export default function Services({...props}) {  
    const servicesProps = {
        maintenance: savData, 
        services: servicesData,        
        servicesInfo: props.servicesInfo,
        savRef: props.savRef,
        startingSav: props.startingSav,
        startingServices: props.startingServices
    }
    return (
        <div>
            <div className="frame-sections">
            <SuspenseWebBowl/>
        </div>
           
            <ServiceArticle {...servicesProps}/>
        </div>
    )
}
