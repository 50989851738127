
import { useEffect, useState } from 'react';
import { CurvedLoading } from '../common';

interface LoadingCurvingProps{
    title: string;
}
interface CurvedLetterI{
   
    angle : number, 
    translateX: number, 
    translateY: number,
    letter: string,
    angleStep: number
}


export default function LoadingCurving(props: LoadingCurvingProps) {
    const [curvingTable, setCurvingTable] = useState<CurvedLetterI[]|[]>([])
    
    function defineLettersPosition(): CurvedLetterI[]{
        //     

        /* step used to place each point at equal distances */
        let angleStep = 2*Math.PI / titleTab.length;
        const radius = 20;

        let curvingProp: CurvedLetterI;
        let startPosition = -90;
        let translateX : number;
        let translateY: number;
        const curvingProps: CurvedLetterI[] = titleTab.map((aLetter, index)=>{           
         
            /*translation relative au diamètre du cercle*/
            translateX = -Math.cos(index * angleStep) * radius;
            translateY = -Math.sin(index * angleStep) * radius;
            let rotation = (360/titleTab.length)*index;
            
            curvingProp = {letter: aLetter, angle : rotation + startPosition, translateX: translateX, translateY: translateY, angleStep: angleStep}
         
            return curvingProp;
    }); 
    
        return curvingProps;
    }

    useEffect(() => {
      const positionTable: CurvedLetterI[] = defineLettersPosition();
      setCurvingTable(positionTable);
      return () => {
        
      }
    }, [])
    
    const titleTab = props.title.split('');
    
  return (
    <div className='loading-word'>{titleTab.map((aLetter: string, index: number)=>{
    
        return <CurvedLoading 
        key={index}
        position={index} {...curvingTable[index]} >
        <span>{aLetter}</span>
        
        </CurvedLoading>
    })}</div>
  )
}
