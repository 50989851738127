import React from 'react';



interface CircleProps{
    width: string;
    height: string;	
	className: string;
	color: string;
    onKeyClick: ()=>void;
}

const CirclePlay : React.FC <CircleProps> = (props: CircleProps) =>{

    return (


<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 className={props.className} width={props.width} height={props.height} viewBox="0 0 113.39 113.39" enableBackground="new 0 0 113.39 113.39" xmlSpace="preserve">
<g id="circle">	
		<circle fill="none" stroke={props.color} strokeLinecap="square" strokeMiterlimit="10" strokeDasharray="25.5029,10.2011,61.2069,3.0603" cx="56.449" cy="56.907" r="47.745"/>
</g>


</svg>

    )
}
export default CirclePlay;

