import AnimatedButton from "./AnimatedButton";

interface BtnProps {
    id: number,
    title: string,
    imgLst: string[]
}



export default function FallBackBtns  ({...props}) {
    return(
        <div className="buttons-position">
        {props.btnList && props.btnList.map((btn:BtnProps)=>
            <AnimatedButton
            onHover={props.handleHover}
            onLeave={props.handleLeave}
            hoveredId={props.hoveredId}
            clickedId={props.clickedId} 
            oldId={props.oldId}
            sameId={props.sameId}
            key={btn.id} 
            images={btn.imgLst !== undefined && btn.imgLst} 
            activId = {0}
            btnId={btn.id}
            title={btn.title}
            onClick={()=>{
                props.onClick(btn.id)
                props.toggleButtonRole(btn.id);
            }} 
            />
            )}
            </div>
            )
        }