import React, {useState, useEffect, useRef, ReactElement, useContext} from 'react';
import {domains, gallery} from "../data/data";
import {CarouselNav} from "./CarouselNav";
import {CarouselDiv, CarouselnavWrapper, DomainsDiv} from "../common/index";
import { TheContext } from '../context/MyContext';
import SuspenseWebBowl from './SuspenseWebBowl';


interface StyledProps{
    isActiv: boolean;
    isPrevious: boolean;
    
}

interface ImgProps {
    id: number,
    img: string,
    
}

interface CarouselProps {
    
    gallery: CarrouselProps[] ;
    activId: number;
    previousId: number;
    furtherId: number;
    map (value:ImgProps) : void;
    onClick (id: number, e:  React.MouseEvent<HTMLButtonElement>) : void;
}
interface HomeProps{
    gallery: CarrouselProps[] ;
    domains: CarrouselDomainProps[];
    showCarousel: boolean; 
  
    //map (value:CarrouselProps) : void;
}
interface CarrouselProps{   
    id: number,
    img: string   
}
interface CarrouselDomainProps{
id: number,
title: ReactElement
}









export const Carousel: React.FC<HomeProps> = (props) => {
    const myContext = useContext(TheContext);
    const [activId, setActivId] = useState<number>(0); 
    const [previousId, setPreviousId] = useState<number>(gallery.length-1);
    
    const [furtherId, setFurtherId] = useState<number>(activId+1);    
    const timeOutValue = 5000;   
    const timerRef = useRef<any>(null);
    const [auto, setAuto] = useState<boolean>(true);
    const [housePaperFirstId, setHousePaperFirstId] = useState<number>(0);
    useEffect(() => {
        autoPlay();
        
        return ()=>clearTimeout(timerRef.current);
    }, [auto, activId])
    
    
    const autoPlay =()=>{
        if (timerRef.current){
            clearTimeout(timerRef.current);
        } 
        timerRef.current = setTimeout(()=>{
            if (auto){
                previousId === gallery.length-1 ? setPreviousId(0) : setPreviousId(activId);
                activId === gallery.length-1 ? setActivId(0) : setActivId(activId + 1);
                
            }     
            }, timeOutValue);
            
        

    }
    
    const onClick = (pActivId: number, event: React.MouseEvent<HTMLButtonElement>)=>{        
        setAuto(false);  
        activId === gallery.length-1 ? setPreviousId(gallery.length-1) : setPreviousId(pActivId-1);
     
        setActivId(pActivId);
        setTimeout(()=>{setAuto(true)}, 5000);
    }
    const onImgClick = ()=>{       
        setAuto(false);       
        activId === gallery.length-1 ? setActivId(0) : setActivId(activId+1);
        setTimeout(()=>{setAuto(true)}, 5000);
    }
   
    const CarouselProps : CarouselProps = {gallery, activId, previousId, furtherId, map(){}, onClick};
    
return <>
<div className="carousel-container">
    <div className='carousel-div'>  
        <div className="theCarousel">
        {props.showCarousel &&
            gallery.map(img=>             
                <CarouselDiv key = {img.id} onClick={()=>{onImgClick()}} isPrevious={previousId === img.id ? true : false} isActiv = {activId === img.id ? true : false}>
                    <img className="img-carousel" src={img.img}/>
                </CarouselDiv>
        
            )
  
        }
        </div>
    </div>
    <div className='carousel-div'> 
    {props.showCarousel &&
            <CarouselnavWrapper>
                <CarouselNav {...CarouselProps} />
            </CarouselnavWrapper>
        }
    </div>

    <div className='carousel-div'>
            <h2 className="title-carousel">Aline BRAND</h2>
       <div className='domains-align'>
    {domains.map(domain=>
            <DomainsDiv key = {domain.id} isPrevious={previousId === domain.id ? true : false} isActiv = {activId === domain.id ? true : false}>
                {domain.title}
            </DomainsDiv>
            )}
        </div>
        <div className="web-bowl-home" >
            <SuspenseWebBowl/>
        </div>
        </div>
        </div>
    </>
    
}


