
const Key =()=>{
    return (        
    <svg className="key-scale" version="1.1" id="Key_layer" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="50px" height="50px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
   <path fill="#C3B4A7" stroke="#504441" strokeWidth="3" strokeMiterlimit="10" d="M227.593,178.291l-37.348,13.316
	c0,0-17.412-60.867-18.5-65.821c6.711-2.654,37.757-11.469,37.757-11.469L227.593,178.291z"/>
<path fill="#C3B4A7" stroke="#504441" strokeWidth="3" strokeMiterlimit="10" d="M209.502,114.317
	c0,0,22.641,15.072,17.261-31.328c-20.781,8.803-41.203,13.046-41.203,13.046s-12.381,23.324-13.815,28.339
	C174.582,123.232,209.502,114.317,209.502,114.317z"/>
<path fill="#A18975" stroke="#504441" strokeWidth="3" strokeMiterlimit="10" d="M227.209,87.433
	c1.742,12.371,42.78,167.786,48.788,202.236c-15.869-0.052-17.964,5.698-17.964,5.698s-41.165-156.176-47.876-179.796
	C232.209,126.266,226.727,96.438,227.209,87.433z"/>
<path fill="none" stroke="#E83A68" strokeWidth="3" strokeMiterlimit="10" d="M309.309,420.55"/>
<path fill="none" stroke="#E83A68" strokeWidth="3" strokeMiterlimit="10" d="M286.31,288.573"/>
<path fill="#C3B4A7" stroke="#504441" strokeWidth="3" strokeMiterlimit="10" d="M263.318,309.841c0-1,9.182-16.672,12.679-20.172
	c6.25-1.521,40.559-0.521,60.363,16.456c22.747,19.5,22.65,43.51,22.65,43.51S362,373,350.5,390.75
	c-9.518,14.69-28.295,24.677-28.295,24.677l-1.478-3.305c0,0,18.476-11.914,12.022-57.372
	C325.509,303.741,263.318,309.841,263.318,309.841z"/>
<path fill="#C3B4A7" stroke="#504441" strokeWidth="3" strokeMiterlimit="10" d="M263.318,308.841
	c0-1,12.679-19.172,12.679-19.172S266.5,288.75,256,292.75c-17.984,8.786-37.575,30.938-37.413,63.219s26.266,59.078,52.214,65.18
	s51.403-5.722,51.403-5.722L319,413.334c0,0-29.114-1.256-53.174-14.256c-18.687-10.097-24.059-30.651-24.326-46.781
	C242.5,329,263.318,308.841,263.318,308.841z"/>
<polygon id="mask" fill="#C3B4A7" points="268.25,308 260.75,308 271.5,291 282.25,291 "/>
    </svg>
    )
}
export default Key