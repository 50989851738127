
import { logos } from '../data/data';
import "../styles/App.css";

export default function Confidence() {
    return (
        <>
        
        <div className="logo-business-div">
        <h1>Merci de votre confiance</h1>
            
            {logos.map(logo=>
                <img className="logo-business-img" key={logo.id} src={logo.img} alt={logo.title}/>
                )}
        </div>
        </>
    )
}
