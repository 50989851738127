
import React, { Suspense } from "react";
import FallBackFrame from "../components/FallBackFrame";
import { JsxInfo, ImgGallery } from "../components/ImgGallery";
import { SiteCards } from "../components/SiteCard";
import SuspenseWebBowl from "../components/SuspenseWebBowl";
import "../styles/App.css";

const myImports = require.context("../../public/Galleries/3D/", true, /\.png|jpe?g$/);
const path = myImports.keys().map(myImport=> myImport.replace("./", "Galleries/3D/"));





export default function Portfolio() {   
    
    
    return(
        <>
        
        <div className="frame-sections">
        <SuspenseWebBowl/>
        </div> 
        <div className="portfolio-section">
        <SiteCards/>
        </div>           
        <div className="portfolio-sections">
        
        <div className='retro-container'>
        <div className='border-retro-title'></div>
        <h2 className='retro-title'>
            Communication visuelle et 3D
        </h2>
        <div className='border-retro-title'></div>
        </div>
        <div className="portfolio-gallery">
        {path.map((anImport: string | undefined, index: number)=>                   
            <ImgGallery  key={index} index={index}  path={anImport}/>
            )}
            </div>
            <div className="portfolio-gallery">
            <div className="copyright">
            © Ces images sont soumises à des droits d'auteur 
            et ne peuvent être utilisées sans le consentement des entreprises qui les ont commandées. 
            </div>
            </div>
            </div>
            </>
            )
        }