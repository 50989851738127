
import SuspenseWebBowl from "../components/SuspenseWebBowl";
import "../styles/App.css"




export default function Prestations() {    
    return (
      <div className="frame-sections">
        <SuspenseWebBowl/>
      </div>
    )
}
