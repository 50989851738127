
import { cards } from '../data/data';
import '../styles/PersCards.css';
import Arrow from './Arrow';

export default function PersCards() {
  const bookingAddress = "https://bookingzone.fr";
  //const bookingAddress = "https://bookingpilot.herokuapp.com/";
    return (     
            <div id="cards-container-id" className="cards-container">
              
              <div className="card-container-alone">
                
                  {cards.map(aCard=>
                  <div  key={aCard.id} className='card-wrapper'>
                  <div className='card'>
                      <div className='card-front'>
                        <p>{aCard.title}</p>
                        <div className='card-price'>
                          <span>{aCard.info}</span>
                        </div>
                        <div className='card-arrow'>
                          <Arrow color="white" className='arrow-card' isLeft={false} width={'50px'} height={'50px'}/>
                        </div>
                      </div>
                      <div className='card-back'>
                        <a href={bookingAddress} target="_blank" className='btn'>{aCard.btn}</a>
                      </div>
                    </div>
                    </div>
                    
        )}         
             
              </div>
            </div>
          );
    
}
