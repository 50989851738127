import React from 'react'

interface SideIconProps{
    color: string;
    phoneNav: boolean;
    
}


export default function SideIconF(props: SideIconProps) {
    const theWidth = props.phoneNav ? "50px" : "50px";
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={theWidth}
        height="50px" viewBox="0 0 50 50" enableBackground="new 0 0 50 50" xmlSpace="preserve">
        <g id="facebook">
        <text fill={props.color} transform="matrix(-4.371139e-008 1 -1 -4.371139e-008 7.3071 17.0513)" fontFamily="'Montserrat-Regular'" fontSize="40">f</text>
        </g>
        
        </svg>
        )
    }
    
    
