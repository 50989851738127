import  { Component, MouseEventHandler, ReactElement, useRef } from 'react';
import "../styles/App.css";
import {Carousel} from "../components/Carousel";
import {Wrapper} from "../common/index";
import InfosProjet from '../components/InfosProjet';
import Confidence from '../components/Confidence';
import {projectData} from "../data/data";
import axios from 'axios';
import React from 'react';


interface CarrouselProps{   
        id: number,
        img: string   
}
interface CarrouselDomainProps{
    id: number,
    title: ReactElement<any, any>
}


interface HomeProps{
    gallery: CarrouselProps[] ;
    domains: CarrouselDomainProps[];
  
}




export default class Home extends Component<HomeProps>{
    CancelToken: any;
    source: any;
    instance: any;   
    timerRef: any;

    

    constructor(props: HomeProps){
        super(props);     
        
        this.timerRef = React.createRef();
    }
  
    
    componentDidMount() {
    }

    componentDidUpdate(prevState: any){
    
    }
    componentWillUnmount(){
      if(this.timerRef.current)
      clearTimeout(this.timerRef.current);
    }

   backupApiIsWorking=()=>{
    const SERVER = "http://localhost:5000/api/test"
    axios.get(`${SERVER}`, {
      cancelToken: this.source.token
    })
    .then (function (response) {
      console.log("on a une réponse : ", response.data.message);
    })
    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        console.log("error handled");
      }
    });
       
   }

    render(){
    
    
    return (
        <>
  
    
    <Wrapper> 
        <Carousel showCarousel={true} gallery = {this.props.gallery} domains={[]}></Carousel>   
    </Wrapper>
    <div className='projet-div'>     
      <div className="info-wrapper">
        <h2 className='subTitle2'>Artisan du web dans le Tarn</h2>
        Je vous accompagne dans vos projets de développement en mettant en avant l'identité de votre marque.
      </div>
    </div>
    <div className="projet-div">
      <h1 className="title2">Votre projet web en trois étapes</h1>
    <InfosProjet projectData = {projectData} />    
    <Confidence/>
    </div>
</>
    )
}
}

