import React from 'react';



interface ArrowProps{
    width: string;
    height: string;
	isLeft: boolean;
	className: string;
	color: string;
}

const ArrowAlone : React.FC <ArrowProps> = (props: ArrowProps) =>{

    return (

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 className={props.className} width={props.width} height={props.height} viewBox="0 0 113.39 113.39" enableBackground="new 0 0 113.39 113.39" xmlSpace="preserve">

<g id="arrow_x5F_left_1_" display={"block"}>
	<g>
		<polygon fill={props.color} points="34.593,53.767 78.407,56.767 34.593,59.768 31.593,59.973 31.593,53.562 		"/>
		<g>
			<polygon fill={props.color} points="54.275,81.061 58.326,76.711 36.873,56.773 58.326,36.83 54.275,32.479 28.151,56.773 			"/>
		</g>
	</g>
</g>
</svg>
    )
}
export default ArrowAlone;

