import React from "react";
import "../styles/App.css";
import NavBtn from "./NavBtn";

interface ImgProps {
    id: number,
    img: string
}

interface CarouselProps {
    gallery: ImgProps[] ;
    activId: number;
    previousId: number;
    furtherId: number;
    onClick (id: number, e:  React.MouseEvent<HTMLButtonElement>) : void;
    
}



export const CarouselNav : React.FC <CarouselProps> = (props: CarouselProps) =>{
const size = "15px";

return <div className="thecarouselnav">
    {props.gallery.map (carouselNav => 
    <button key={carouselNav.id} type = "button" onClick={(e)=>props.onClick(carouselNav.id, e)} className = "nav-btn">{carouselNav.id === props.activId ? 
    <NavBtn 
    width={size} 
    height={size} 
    colorFillCenter="#4B5943"
    colorStrokeCenter="#4B5943"
    colorFillMain= "#A1A27E"
    colorStrokeMain="#80856D"    
    /> : 
    <NavBtn 
    width={size} 
    height={size} 
    colorFillCenter="none"
    colorStrokeCenter="#A57813"
    colorFillMain="none"
    colorStrokeMain="E2DEA6"
    
    />  
    }</button>)}
    </div>
}