import { Suspense, useContext, useEffect, useState } from "react";
import { TheContext } from '../context/MyContext';
import React from 'react'
import SimpleFallBack from "./SimpleFallback";
const LazyFrame = React.lazy(()=> import('../components/Frame'));

const webBowlImports = require.context("../../public/Anims/Web/", true, /\.png$/);
const webBowlPath = webBowlImports.keys().map(myImport=>myImport.replace("./", "Anims/Web/"));

export default function SuspenseWebBowl() {
    const [isLoaded, setIsLoaded] = useState(false);  
    const myContext = useContext(TheContext);
useEffect(()=>{
    setIsLoaded(webBowlPath.length === 16);    
}


);

const handleWebBowlClick=()=>{
    console.log("on passe dans handlebowlclick");
    // toggleplay house anim
    myContext.letsPlayPaperHouse ? myContext.setLetsPlayPaperHouse(false) : myContext.setLetsPlayPaperHouse(true);
}

return (
    <>
   <button className='bowl-btn' onClick={()=>handleWebBowlClick()}>
        <Suspense  fallback={<SimpleFallBack />}>
        {isLoaded 
        ? 
        <LazyFrame id={-1} frames={webBowlPath} letsAnim={true}/> 
            
        :
        <SimpleFallBack /> }
        </Suspense>
    </button>
    </>
  )

}


